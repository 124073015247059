import { recorded_content_media_types } from "constants/recordedContent";
import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import {
  MEDIA_LIBRARY_FILTER_KEYS,
  REDUX_STATE_KEYS,
  UPLOAD_QUEUE_STATUS,
} from "features/MediaLibrary/constants/MediaLibrary.constants";
import {
  uploadImageOrDocFile,
  uploadVdocipherNonDrmVideo,
  uploadWistiaVideo,
} from "features/MediaLibrary/utils/MediaLibrary.ApiCalls";
import { getUploader } from "features/MediaLibrary/utils/MediaLibrary.utils";
import { channel } from "redux-saga";
import {
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { RECORDED_CONTENT_VIDEO_SOURCE } from "schedule-v2/RecordedContent/recordedContent.data";
import { isRequestSuccessful } from "utils/Utils";
import {
  createFileUploadSuccessAction,
  createRefetchOnUploadSuccessAction,
  createRequestFileUploadAction,
  createSetFileUploadErrorAction,
  createSetMediaListingsAction,
  startFileUploading,
} from "../MediaLibrary.actionCreators";
import {
  EXLY_MEDIA_LIBRARY__CANCEL_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__CANCEL_UPLOAD_QUEUE,
  EXLY_MEDIA_LIBRARY__ENQUEUE_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__REFETCH_ON_UPLOAD_SUCCESS,
  EXLY_MEDIA_LIBRARY__REQUEST_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__UPDATE_CONCURRENT_UPLOADS_COUNT,
} from "../MediaLibrary.actions";

// to maintain running upload worker, we can cancel it on changing workers count (action: EXLY_MEDIA_LIBRARY__UPDATE_CONCURRENT_UPLOADS_COUNT)
let uploadTask;

function* fetchMediaLibNewListings(action) {
  const { payload } = action || {};

  const params = { page: 1, ...payload };

  try {
    const {
      is_video_drm_enabled,
      [MEDIA_LIBRARY_FILTER_KEYS.file_type]: type,
      [MEDIA_LIBRARY_FILTER_KEYS.file_name]: search,
    } = yield select((state) => state.mediaLibrary);

    const data = yield call(
      dataProvider.custom_request,
      api.media_library.get_media_library,
      apiMethods.GET,
      { ...params, is_drm_flow: is_video_drm_enabled, type, search }
    );
    if (isRequestSuccessful(data?.status)) {
      yield put(
        createSetMediaListingsAction({ page: params.page, ...data?.data })
      );
    }
  } catch (err) {
    yield put(createSetMediaListingsAction(null));
  }
}

function* fileUploader(payload) {
  const { onUploadSuccess, onProgress } = payload;
  const abortController = new AbortController();

  let startTime;
  let lastLoaded = 0;
  let uploadRate = 0; // bytes per second

  const handleProgress = (event) => {
    const { total, loaded } = event;
    const currentTime = new Date().getTime();
    const elapsedTime = (currentTime - startTime) / 1000; // seconds
    const bytesUploaded = event.loaded - lastLoaded;
    uploadRate = bytesUploaded / elapsedTime;
    const totalSize = event.total;
    const bytesRemaining = totalSize - event.loaded;
    const estimatedTimeRemaining = bytesRemaining / uploadRate;

    lastLoaded = event.loaded;
    startTime = currentTime;

    if (onProgress)
      onProgress({
        id: payload.id,
        progress: Math.round((loaded / total) * 100),
        timeLeft: estimatedTimeRemaining,
        uploadRate,
        bytesRemaining,
      });
  };

  yield put(startFileUploading(payload.id)); // mark current file upload status as started (UPLOAD_QUEUE_STATUS.UPLOADING)

  const { type, videoSource } = payload;
  const isVideoFile = type === recorded_content_media_types.video;
  let uploader = uploadImageOrDocFile;

  if (isVideoFile) {
    uploader =
      videoSource === RECORDED_CONTENT_VIDEO_SOURCE.VDOCIPHER_NON_DRM
        ? uploadVdocipherNonDrmVideo
        : uploadWistiaVideo;
  }

  try {
    const { task, aborted } = yield race({
      // task: call(delay, t),
      task: call(getUploader, uploader, {
        file: payload.file,
        title: payload.file.name,
        abortSignal: abortController.signal,
        onProgress: handleProgress, // marks upload progress for current file upload
      }),
      aborted: take(
        (action) =>
          action.type === EXLY_MEDIA_LIBRARY__CANCEL_UPLOAD_QUEUE || // abort on closing the uploads list popup
          (action.type === EXLY_MEDIA_LIBRARY__CANCEL_FILE_UPLOAD && // abort the current file upload upload if creator cancel the current file upload
            action.payload.id === payload.id)
      ),
    });

    if (aborted) {
      abortController.abort(); // abort upload if user cancels the file upload or cancel the entire queue by closing the uploads list popup
    } else {
      yield put(createFileUploadSuccessAction(payload)); // mark file upload status as uploaded (UPLOAD_QUEUE_STATUS.UPLOADING)

      if (onUploadSuccess) {
        const { mediaLibraryContentPayload } = task;
        onUploadSuccess(mediaLibraryContentPayload);
      }

      yield put(createRefetchOnUploadSuccessAction()); // refetch media items
    }
  } catch (error) {
    yield put(
      createSetFileUploadErrorAction({ id: payload.id, error: error.message })
    );
  }
}

function* hasItemInUploadQueue(uploadId) {
  const uploadQueue = yield select(
    (state) => state.mediaLibrary[REDUX_STATE_KEYS.upload_queue]
  );
  return uploadQueue.some((item) => item.id === uploadId);
}

function* fileUploadRequestsWorker(uploadRequestsChannel) {
  while (true) {
    const payload = yield take(uploadRequestsChannel);

    // Start the file upload if this queued file is still present in upload queue
    // For explanation, please learn how redux saga channel works
    if (
      yield hasItemInUploadQueue(payload.id) &&
        payload.status !== UPLOAD_QUEUE_STATUS.FAILED
    )
      yield call(fileUploader, payload);
  }
}

export function* watchFileUploadRequests(count) {
  // create a channel to queue incoming requests
  const uploadRequestsChannel = yield call(channel);

  // create `count` worker 'threads' for limiting the number of concurrent file uploads
  for (let i = 0; i < count; i++)
    yield fork(fileUploadRequestsWorker, uploadRequestsChannel);

  while (true) {
    const { payload } = yield take(EXLY_MEDIA_LIBRARY__REQUEST_FILE_UPLOAD);
    yield put(uploadRequestsChannel, payload);
  }
}

function* updateConcurrentUploadsCountSaga(action) {
  const { count } = action.payload;

  if (uploadTask) {
    yield cancel(uploadTask);
  }

  uploadTask = yield fork(watchFileUploadRequests, count); // restart with new worker count
}

export function* watchUpdateConcurrentUploadsCount() {
  yield takeLatest(
    EXLY_MEDIA_LIBRARY__UPDATE_CONCURRENT_UPLOADS_COUNT,
    updateConcurrentUploadsCountSaga
  );
}

export function* enqueueFileUpload() {
  yield takeEvery(EXLY_MEDIA_LIBRARY__ENQUEUE_FILE_UPLOAD, function* (action) {
    // assign uploadCounter as file upload item id
    // refer to dev comments on initial state in src/features/MediaLibrary/redux/MediaLibrary.reducer.js
    const uploadCounter = yield select(
      (state) => state.mediaLibrary[REDUX_STATE_KEYS.upload_counter]
    );
    yield put(
      createRequestFileUploadAction({ ...action.payload, id: uploadCounter })
    );
  });
}

export function* mediaLibFileUploadSaga() {
  yield takeEvery(
    EXLY_MEDIA_LIBRARY__REFETCH_ON_UPLOAD_SUCCESS,
    fetchMediaLibNewListings
  );
}
