// if: lines of code > 300
// then: breakdown this file and move to src/features/OfferingVariations/constants folder

import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { GET_OFFERING_VARIATIONS } from "./OfferingVariations.apis";
import moduleStyle from "./pages/OfferingVariationsList/Components/Columns/Columns.module.css";

export const offering_variations_resource_apis = {
  [app_route_ids[app_route_keys.offering_variations]]: ({ pathname }) => {
    const tempArray = pathname.split("/");
    const listing_uuid = tempArray[tempArray.length - 1]; // fetching listing uuid which is present in the url match params

    return {
      api_end_point: GET_OFFERING_VARIATIONS,
      queries: { listing_uuid },
    };
  },
};

export const CREATE_VARIATION_CTA_TEXT = "Create Variation";
export const EDIT_VARIATION_CTA_TEXT = "Save Changes";

export const empty_state_props = {
  content_title: "No variations yet.",
  empty_list_icon: "./assets/images/offering_variations/empty.png",
  alt: "no variations",
  children: "All variations connected to this offering will be visible here.",
};

export const VARIANT_TYPE_IDS = {
  default: 1,
  others: 2,
};

export const VARIANT_TYPE = {
  [VARIANT_TYPE_IDS.default]: {
    label: "Default",
    value: VARIANT_TYPE_IDS.default,
    class: `${moduleStyle.default_type_container}`,
  },
  [VARIANT_TYPE_IDS.others]: {
    label: "Custom",
    value: VARIANT_TYPE_IDS.others,
  },
};
