import { COMMON_WIDTH_RESOLUTIONS } from "features/Common/modules/ExlyImage/constants/ExlyImage.constants";
import { getOptimisedS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";

export const whats_next_keys = {
  has_completed_all_steps: {
    key: "has_completed_all_steps",
  },
  has_offer_enabled: {
    key: "has_offer_enabled",
  },
  store_website_design: {
    key: "store_website_design",
  },
  has_live_listing: {
    key: "has_live_listing",
  },
  has_transacted: {
    key: "has_transacted",
  },
  plan_thank_you_visit_state: {
    key: "plan_thank_you_visit_state",
  },
  is_onboarded: {
    key: "is_onboarded",
  },
  has_custom_email: {
    key: "has_custom_email",
  },
  has_whatsapp_campaign: {
    key: "has_whatsapp_campaign",
  },
  has_filled_account_details: {
    key: "has_filled_account_details",
  },
  has_stripe_link: {
    key: "has_stripe_link",
  },
  has_subdomain: {
    key: "has_subdomain",
  },
  has_sales_page: {
    key: "has_sales_page",
  },
  has_run_ads: {
    key: "has_run_ads",
  },
  has_shared_sales_page: {
    key: "has_shared_sales_page",
  },
  last_profile_update: {
    key: "last_profile_update",
    /** Key which stores what was the time stamp
     * at which the update user details call was hit. */
  },
  completion_percentage: {
    key: "completion_percentage",
  },
  has_double_transacted: {
    key: "has_double_transacted",
  },
  has_leads: {
    key: "has_leads",
  },
};

export const WHATS_NEXT_ASSETS_URL = `onboarding/whats_next`;
export const WHATS_NEXT_CUSTOM_EVENT = "whatsNextDataUpdate";

export const header_background_img = `url(${getOptimisedS3AssetCDNUrl(
  `${WHATS_NEXT_ASSETS_URL}/get_started.png`
)}?format=auto&width=${COMMON_WIDTH_RESOLUTIONS.WIDTH_2160})`;
export const start_tour_img = getOptimisedS3AssetCDNUrl(
  `${WHATS_NEXT_ASSETS_URL}/play_circle.png`
);
export const progress_bg = getOptimisedS3AssetCDNUrl(
  `${WHATS_NEXT_ASSETS_URL}/elipsis.svg`
);
export const bonus_stripe_img = getOptimisedS3AssetCDNUrl(
  `${WHATS_NEXT_ASSETS_URL}/bonus.svg`
);
