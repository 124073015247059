import React from "react";
import { useHistory } from "react-router";
import { api } from "data";
import { appendUrlParams } from "utils/urlUtils";
import styles from "./TotalBookingsField.module.css";

const TotalBookingsField = (props: {
  dynamicLinkUuid: string;
  bookingsCount: number;
}) => {
  const history = useHistory();
  const { dynamicLinkUuid, bookingsCount = 0 } = props;

  return (
    <div
      className={styles.bookingsTextStyle}
      onClick={() => {
        history.push(
          appendUrlParams(`/${api.transaction_list}`, {
            dynamic_link_id: dynamicLinkUuid,
          })
        );
      }}
    >
      View Bookings ({bookingsCount})
    </div>
  );
};

export default TotalBookingsField;
