import { feature_activation_keys } from "features/FeatureActivationStatus/FeatureActivationStatus.constants";
import useFeaturesActivationStatus from "features/FeatureActivationStatus/utils/useFeaturesActivationStatus";
import { useEffect, useState } from "react";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";

export const useIsMediaLibEnabled = () => {
  const [isMediaLibraryEnabled, setIsMediaLibraryEnabled] = useState(false);

  const { loading, featuresActivationStatus } = useFeaturesActivationStatus();

  useEffect(() => {
    const mediaLibrary =
      featuresActivationStatus[
        feature_activation_keys.enable_media_library.key
      ];
    const isMediaLibraryHidden = orgPermissions.isNavigationItemHidden(
      RESOURCE_KEYS.SECTIONS.MEDIA_LIBRARY
    );
    setIsMediaLibraryEnabled(mediaLibrary && !isMediaLibraryHidden);
  }, [loading, featuresActivationStatus]);

  return { loading, isMediaLibraryEnabled };
};
