import React from "react";
import { shuffleArray } from "features/Common/modules/DataTypes/utils/array";
import { ONBOARDING_ASSETS_URL } from "features/Onboarding/constants/Onboarding.constants";
import { IMAGE_OPTIMIZATION_CDN_URL } from "features/Common/modules/ExlyImage/constants/ExlyImage.constants";
// SKU selection options
export const offerings_selection_values = {
  LIVE_WEBINAR: "Live Sessions",
  WEBINAR_WORKSHOP: "Webinars & Workshops",
  COURSES: "Courses",
  COMMUNITIES: "Communities",
  PRODUCTS: "Products",
};

// su selection options config
export const base_offerings_selection_options = [
  {
    value: offerings_selection_values.LIVE_WEBINAR,
    label: "Live Sessions",
    desc: "Organize private 1:1 consultations, live classes, group discussions, doubt classes and more!",
    primaryColor: "#847920",
    bgColor: "#FFFBDE",
  },
  {
    value: offerings_selection_values.WEBINAR_WORKSHOP,
    label: "Webinars & Workshops",
    desc: "Conduct live webinars, presentations and leverage interactive Q&A and attendance tracking.",
    primaryColor: "#49903A",
    bgColor: "#E5F9E1",
  },
  {
    value: offerings_selection_values.COURSES,
    label: "Courses",
    desc: "Host, sell & manage recorded courses. Engage learners with live sessions, assessments and certificates.",
    primaryColor: "#286399",
    bgColor: "#E8F4FF",
  },
  {
    value: offerings_selection_values.COMMUNITIES,
    label: "Communities",
    desc: "Manage WhatsApp / Telegram communities or your own branded community, to drive engagement.",
    primaryColor: "#884C84",
    bgColor: "#FFF1FE",
  },
];

// sku selectin last option config
export const last_offerings_selection_option = [
  {
    value: offerings_selection_values.PRODUCTS,
    label: "Products",
    desc: "Sell and ship physical goods such as souvenirs, art works, merchandise, prints etc across the country.",
    primaryColor: "#CE7336",
    bgColor: "#FFF3EB",
  },
];

// need to shuffle sku selection config
export const shuffled_offerings_selection_options = [
  ...shuffleArray(base_offerings_selection_options),
  ...last_offerings_selection_option,
];

// first sku selection option after shuffling
export const [first_offerings_selection_option] =
  shuffled_offerings_selection_options;

// assets url for sku selection
export const offerings_selection_base_url = `${ONBOARDING_ASSETS_URL}/questions/skuSelection/`;

export const offerings_selection_icons_base_url = `${IMAGE_OPTIMIZATION_CDN_URL}/onboarding/skuSelectionIcons/`;
export const offerings_selection_info_base_url = `${offerings_selection_base_url}infoModal/`;

// all icons related to sku config
export const offerings_selection_icons = {
  CALENDLY: `${offerings_selection_icons_base_url}calendly.svg`,
  ZOOM: `${offerings_selection_icons_base_url}zoom.svg`,
  SET_MORE: `${offerings_selection_icons_base_url}set_more.svg`,
  ZOHO: `${offerings_selection_icons_base_url}zoho.svg`,
  E_WEBINAR: `${offerings_selection_icons_base_url}e_webinar.svg`,
  MEET: `${offerings_selection_icons_base_url}meet.svg`,
  TEACHABLE: `${offerings_selection_icons_base_url}teachable.svg`,
  KAJABI: `${offerings_selection_icons_base_url}kajabi.svg`,
  GRAPHY: `${offerings_selection_icons_base_url}graphy.svg`,
  REDDIT: `${offerings_selection_icons_base_url}reddit.svg`,
  FB_GROUPS: `${offerings_selection_icons_base_url}fb.svg`,
  CIRCLE: `${offerings_selection_icons_base_url}circle.svg`,
  DISCORD: `${offerings_selection_icons_base_url}discord.svg`,
  LEARN_WORLDS: `${offerings_selection_icons_base_url}learn_world.svg`,
};

// information config for each sku option
export const offerings_selection_wise_info_config = {
  [offerings_selection_values.LIVE_WEBINAR]: {
    primaryColor: "#EAB600",
    desktop_right_gradient: { primary: "#FFFEF3", secondary: "#FFFBDF" },
    desktop_right_primary: "#A89816",
    title: (
      <>
        Elevate Your <span>Group Sessions</span> Experience
      </>
    ),
    description: (
      <>
        Conduct interactive <span>classes, appointments, and discussions</span>
        &nbsp; with your customers, fostering collaboration and coaching.
      </>
    ),
    img: `${offerings_selection_info_base_url}liveWebinar/live_webinar_info_main.svg`,
    description_config_desktop: {
      icons: [
        offerings_selection_icons.CALENDLY,
        offerings_selection_icons.ZOOM,
        offerings_selection_icons.MEET,
        offerings_selection_icons.SET_MORE,
      ],
      video_url: "https://www.youtube-nocookie.com/watch?v=g_ys0XJPHHQ",
      thumbnail_url: `/onboarding/live_sessions_onboarding.png?format=auto&width=500`,
      highlights: [
        "Class Customization",
        "Session Recording",
        "Flexible Booking",
        "Anytime Access",
        "Personal Consults",
        "Easy Reschedule",
        "Add Breaks",
      ],
    },
    description_config_mobile: [
      {
        img: `${offerings_selection_info_base_url}liveWebinar/info1.svg`,
        title: (
          <div>
            Customize <span>Your Classes</span>
          </div>
        ),
        description: (
          <>
            Craft classes to fit your vision! Choose the duration of each
            session and set flexible schedules. Seamlessly adjust to your
            customers&apos; needs and preferences. 🙌🏻
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}liveWebinar/info2.svg`,
        title: (
          <div>
            Effortless <span>Session Recordings</span>
          </div>
        ),
        description: (
          <>
            Automatically share Zoom recordings of your sessions with customers,
            ensuring they never miss a beat. Enhance accessibility and provide
            valuable resources with ease. 🙆🏻‍♀️
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}liveWebinar/info3.svg`,
        title: (
          <div>
            Flexible booking <span>options</span>
          </div>
        ),
        description: (
          <>
            Create day and time-wise batches for group classes. Streamline the
            booking process and prioritize accessibility for all.💎
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}liveWebinar/info4.svg`,
        title: (
          <div>
            <span>Seamless </span>Access Anytime
          </div>
        ),
        description: (
          <>
            With our flexible rolling sessions, customers can join anytime and
            seamlessly catch up from the beginning. Ensure every learner&apos;s
            journey is valued and uninterrupted. 🚀
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}liveWebinar/info5.svg`,
        title: (
          <div>
            <span> Personalized </span>Consultations
          </div>
        ),
        description: (
          <>
            Offer appointment slots based on your availability, allowing them to
            book sessions at their convenience. Seamlessly adjust to changing
            schedules and preferences. 📆
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}liveWebinar/info6.svg`,
        title: (
          <div>
            <span>Reschedule </span>With Ease
          </div>
        ),
        description: (
          <>
            Allow customers to easily reschedule appointments after booking,
            ensuring they can adjust their schedules as needed. Streamline the
            rescheduling process for both parties. 💜
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}liveWebinar/info7.svg`,
        title: (
          <div>
            Add Breaks <span>Between Sessions</span>
          </div>
        ),
        description: (
          <>
            Take time for yourself between sessions! Add break durations to your
            schedule to account for rest or preparation time. Ensure you&apos;re
            refreshed and ready to deliver exceptional experiences. ⛔️
          </>
        ),
      },
    ],
  },
  [offerings_selection_values.WEBINAR_WORKSHOP]: {
    img: `${offerings_selection_info_base_url}webinarWorkshops/webinar_workshop_info_main.svg`,
    primaryColor: "#80BC00",
    desktop_right_gradient: { primary: "#F6FCF5", secondary: "#E6F5E2" },
    desktop_right_primary: "#557D00",
    title: (
      <>
        Transform <span>Workshops </span>and <span>Webinars</span>
      </>
    ),
    description: (
      <>
        Unleash the potential of your expertise! Conduct&nbsp;
        <span>engaging workshops & webinars</span> to captivate your audience.
      </>
    ),
    description_config_desktop: {
      icons: [
        offerings_selection_icons.CALENDLY,
        offerings_selection_icons.ZOOM,
        offerings_selection_icons.MEET,
        offerings_selection_icons.E_WEBINAR,
        offerings_selection_icons.ZOHO,
      ],
      video_url: "https://www.youtube-nocookie.com/watch?v=qGLEtqTffS0",
      thumbnail_url: `/onboarding/webinar_onboarding.png?format=auto&width=500`,
      highlights: ["Custom Schedule", "Frequent Timing"],
    },
    description_config_mobile: [
      {
        img: `${offerings_selection_info_base_url}webinarWorkshops/info1.svg`,
        title: (
          <>
            Customize <span>Scheduling</span>
          </>
        ),
        description: (
          <>
            Set up workshops or webinars by selecting specific dates, times, and
            durations. Tailor each event to fit your schedule and maximize
            audience engagement. ✨
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}webinarWorkshops/info2.svg`,
        title: (
          <>
            Versatile <span>Webinar Frequencies</span>
          </>
        ),
        description: (
          <>
            Whether it&apos;s a one-time event, on selected dates, or repeating
            on specific days of the week, customize the schedule to best suit
            your audience&apos;s needs and your availability. 👀
          </>
        ),
      },
    ],
  },
  [offerings_selection_values.COURSES]: {
    img: `${offerings_selection_info_base_url}courses/courses_info_main.svg`,
    primaryColor: "#00AEE6",
    desktop_right_gradient: { primary: "#F4FAFF", secondary: "#EBF5FF" },
    desktop_right_primary: "#286DAD",
    title: (
      <>
        Inspire Learners With Enagaging <span>Courses</span>
      </>
    ),
    description: (
      <>
        Unlock the power of <span>pre-recorded, structured courses</span>! Share
        your knowledge through captivating content and interactive features.
      </>
    ),
    description_config_desktop: {
      video_url: "https://www.youtube-nocookie.com/watch?v=RGcgF3sD1PY",
      thumbnail_url: `/onboarding/courses_onboarding.png?format=auto&width=500`,
      icons: [
        offerings_selection_icons.TEACHABLE,
        offerings_selection_icons.KAJABI,
        offerings_selection_icons.GRAPHY,
        offerings_selection_icons.LEARN_WORLDS,
      ],
      highlights: [
        "Interactive Lessons",
        "Flexible Exams",
        "Comments & Chats",
        "Content Security",
        "Progress Tracking",
        "Leaderboards",
      ],
    },
    description_config_mobile: [
      {
        img: `${offerings_selection_info_base_url}courses/info1.svg`,
        title: (
          <>
            <span>Dynamic</span> & Intercative Lessons
          </>
        ),
        description: (
          <>
            Use videos, images, text, embeds, exams, forms, links, and upsells
            to craft highly engaging content. Provide a rich, immersive learning
            experience for your customers. 🔮
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}courses/info2.svg`,
        title: (
          <>
            <span>Flexible</span> Exam Options
          </>
        ),
        description: (
          <>
            Design both auto-evaluation and manual evaluation exams. Customize
            your evaluation methods to best support your learners progress. ✔️
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}courses/info3.svg`,
        title: (
          <>
            <span>Interactions</span> and Commenting
          </>
        ),
        description: (
          <>
            Encourage interaction within your course&apos;s customer community
            through comments. Foster a collaborative learning environment. 🤝‍
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}courses/info4.svg`,
        title: (
          <>
            <span>Secure</span> Your Content
          </>
        ),
        description: (
          <>
            Protect your intellectual property! Enable watermarking to trace
            unauthorized sharing back to the source.🚨
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}courses/info5.svg`,
        title: (
          <>
            In - Depth <span>Progress Tracking</span>
          </>
        ),
        description: (
          <>
            Track customer progress, view engagement and completion stats, exam
            and form responses, current locations in the course, and more—all in
            one place. 📊
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}courses/info6.svg`,
        title: (
          <>
            Engage with <span>Leaderboard</span>
          </>
        ),
        description: (
          <>
            Implement a course leaderboard to drive competition and engagement.
            Encourage learners to strive for the top spot and enhance their
            learning experience.
          </>
        ),
      },
    ],
  },
  [offerings_selection_values.COMMUNITIES]: {
    img: `${offerings_selection_info_base_url}community/community_info_main.svg`,
    primaryColor: "#E774A7",
    desktop_right_gradient: { primary: "#FFF7FE", secondary: "#FEEEFD" },
    desktop_right_primary: "#C153B9",
    title: (
      <>
        Build Thriving <span>Communities</span>
      </>
    ),
    description: (
      <>
        Create engaging communities on <span>WhatsApp, Telegram</span>, or your
        own <span>branded platform</span>. Connect, share content, and grow your
        audience!
      </>
    ),
    description_config_desktop: {
      icons: [
        offerings_selection_icons.CIRCLE,
        offerings_selection_icons.DISCORD,
        offerings_selection_icons.FB_GROUPS,
        offerings_selection_icons.REDDIT,
      ],
      video_url: "https://www.youtube-nocookie.com/watch?v=T3SN99_j5DI",
      thumbnail_url: `/onboarding/community_onboarding.png?format=auto&width=500`,
      highlights: [
        "Branded Spaces",
        "Smooth Messaging",
        "Community Revenue",
        "Ongoing Engagement",
        "WhatsApp Revenue",
      ],
    },
    description_config_mobile: [
      {
        img: `${offerings_selection_info_base_url}community/info1.svg`,
        title: (
          <>
            <span>Dynamic</span> Branded Community
          </>
        ),
        description: (
          <>
            Manage posts, groups, and channels, provide member access, and
            effortlessly add or remove members. Embed content directly within
            the community and keep your audience engaged. 🎉
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}community/info2.svg`,
        title: (
          <>
            <span>Seamless</span> Communications
          </>
        ),
        description: (
          <>
            Use direct messaging and group chats to foster meaningful
            interactions. Build strong relationships and provide personalized
            support within your community. 🙆🏻‍♀️
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}community/info3.svg`,
        title: (
          <>
            <span>Monetize</span> Your Community
          </>
        ),
        description: (
          <>
            Upsell other offerings and link bonus content to your community.
            Automatically add bonus offerings to members purchases, creating a
            seamless and rewarding experience. ✨
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}community/info4.svg`,
        title: (
          <>
            Sustainable <span>Engagement</span>
          </>
        ),
        description: (
          <>
            Offer ongoing value and exclusive content through community
            subscriptions. Ensure continuous growth and engagement within your
            branded platform. ⚡️
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}community/info5.svg`,
        title: (
          <>
            Monetize <span>WA Communities</span>
          </>
        ),
        description: (
          <>
            Easily manage paid entries into your WhatsApp communities! Automate
            payments, redirect users to new groups once full, and keep your
            communities organized and growing.💚
          </>
        ),
      },
    ],
  },
  [offerings_selection_values.PRODUCTS]: {
    img: `${offerings_selection_info_base_url}products/products_info_main.svg`,
    primaryColor: "#EA6F15",
    desktop_right_gradient: { primary: "#FFF8F3", secondary: "#FFF3EB" },
    desktop_right_primary: "#CE7336",
    title: (
      <>
        Organize and Sell <span>Your Merch</span>
      </>
    ),
    description: (
      <>
        Seamlessly organize and sell <span>physical</span> or&nbsp;
        <span>digital products</span> to your customers. Create a professional
        and engaging shopping experience.
      </>
    ),
    description_config_desktop: {
      video_url: "https://www.youtube-nocookie.com/watch?v=YVGr8LfdSNM",
      thumbnail_url: `/onboarding/merchandise_onboarding.png?format=auto&width=500`,
      highlights: [
        "Product Showcase",
        "Easy Logistics",
        "Stock Visibility",
        "Product Variety",
        "Sales Assurance",
      ],
    },
    description_config_mobile: [
      {
        img: `${offerings_selection_info_base_url}products/info1.svg`,
        title: (
          <>
            Stunning <span>Product Displays</span>
          </>
        ),
        description: (
          <>
            Upload multiple images of your merchandise, displayed in an
            attractive carousel format. Highlight every angle and detail to
            captivate your customers. 🏞️
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}products/info2.svg`,
        title: (
          <>
            <span>Easy</span> Logistics Management
          </>
        ),
        description: (
          <>
            Integrate with Shiprocket to manage shipping and fulfillment
            directly within Exly. Streamline your operations and ensure timely
            delivery of your products. 📦
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}products/info3.svg`,
        title: (
          <>
            <span>Transparent</span> <>Stock Levels</>
          </>
        ),
        description: (
          <>
            Keep your customers informed! Add stock quantities to each product
            to let customers know availability. Maintain transparency and manage
            inventory with ease. 🔖
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}products/info4.svg`,
        title: (
          <>
            Versatile <span>Product Options</span>
          </>
        ),
        description: (
          <>
            Add variants to your products, such as size, color, style, or custom
            options. Set prices, stock quantities, and images for each variant,
            providing a tailored shopping experience. 🔸🔹
          </>
        ),
      },
      {
        img: `${offerings_selection_info_base_url}products/info5.svg`,
        title: (
          <>
            Never Miss A <span>Sale</span>
          </>
        ),
        description: (
          <>
            Stay flexible! Enable the option to allow orders even when stock is
            unavailable. Capture sales and manage backorders efficiently,
            ensuring no opportunity is missed. 💰
          </>
        ),
      },
    ],
  },
};
