import { getOptimisedS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import {
  WHATS_NEXT_ASSETS_URL,
  whats_next_keys,
} from "features/Onboarding/modules/WhatsNext/WhatsNext.constants";

export const SET_UP_WEBSITE_KEYS = {
  WEBPAGE: whats_next_keys.store_website_design.key,
  HAS_SUBDOMAIN: whats_next_keys.has_subdomain.key,
};

export const DESIGN_YOUR_WEBSITE_VIDEO_LINK =
  "https://www.youtube-nocookie.com/watch?v=UzxSvJBXwVE";

export const link_domain_icon = getOptimisedS3AssetCDNUrl(
  `${WHATS_NEXT_ASSETS_URL}/link_subdomain.svg`
);
