import { IMediaLibrary } from "../MediaLibrary.interfaces";
import {
  EXLY_MEDIA_LIBRARY__CANCEL_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__CANCEL_UPLOAD_QUEUE,
  EXLY_MEDIA_LIBRARY__ENQUEUE_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__FETCH_MEDIA_LISTING,
  EXLY_MEDIA_LIBRARY__FILE_UPLOAD_SUCCESS,
  EXLY_MEDIA_LIBRARY__HIDE_VIDEO_UPLOADER_MODAL,
  EXLY_MEDIA_LIBRARY__REFETCH_ON_UPLOAD_SUCCESS,
  EXLY_MEDIA_LIBRARY__REQUEST_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__RESET,
  EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_ERROR,
  EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_PROGRESS,
  EXLY_MEDIA_LIBRARY__SET_FILTER,
  EXLY_MEDIA_LIBRARY__SET_MEDIA_LISTING,
  EXLY_MEDIA_LIBRARY__SHOW_VIDEO_UPLOADER_MODAL,
  EXLY_MEDIA_LIBRARY__START_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__UPDATE_CONCURRENT_UPLOADS_COUNT,
  EXLY_MEDIA_LIBRARY__UPDATE_MEDIA_LISTING,
} from "./MediaLibrary.actions";

export const createEnqueueFileUploadAction = (payload: {
  file: File;
  type: number;
  videoSource?: number;
  status?: number;
  error?: string;
  // eslint-disable-next-line no-unused-vars
  onProgress?: (params: {
    id: string;
    progress: number;
    timeLeft: number;
    uploadRate: number;
    bytesRemaining: number;
  }) => void;
  // eslint-disable-next-line no-unused-vars
  onUploadSuccess?: (mediaItem: IMediaLibrary.IMedia) => void;
}) => ({
  type: EXLY_MEDIA_LIBRARY__ENQUEUE_FILE_UPLOAD,
  payload,
});

export const createCancelFileUploadAction = (id: string) => ({
  type: EXLY_MEDIA_LIBRARY__CANCEL_FILE_UPLOAD,
  payload: { id },
});

export const createClearUploadQueueAction = () => ({
  type: EXLY_MEDIA_LIBRARY__CANCEL_UPLOAD_QUEUE,
});

export const uploadFileProgress = (params: {
  id: string;
  progress: number;
  timeLeft: number;
  uploadRate: number;
  bytesRemaining: number;
}) => ({
  type: EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_PROGRESS,
  payload: params,
});

export const startFileUploading = (id: string) => ({
  type: EXLY_MEDIA_LIBRARY__START_FILE_UPLOAD,
  payload: { id },
});

export const createMediaLibraryFetchListingAction = (payload?: {
  [filterKey: string]: string | number | boolean | undefined;
}) => ({
  type: EXLY_MEDIA_LIBRARY__FETCH_MEDIA_LISTING,
  payload: { page: 1, ...payload },
});

export const createMediaLibraryFilterAction = (payload?: {
  type?: number;
  search?: string;
}) => ({
  type: EXLY_MEDIA_LIBRARY__SET_FILTER,
  payload,
});

export const createFileUploadSuccessAction = (payload: { id: string }) => ({
  type: EXLY_MEDIA_LIBRARY__FILE_UPLOAD_SUCCESS,
  payload,
});

export const createRequestFileUploadAction = (payload: {
  file: File;
  id: string;
  // eslint-disable-next-line no-unused-vars
  onProgress?: (params: {
    id: string;
    progress: number;
    timeLeft: number;
    uploadRate: number;
    bytesRemaining: number;
  }) => void;
}) => ({
  type: EXLY_MEDIA_LIBRARY__REQUEST_FILE_UPLOAD,
  payload,
});

export const createSetConcurrentUploadsCountAction = ({
  count,
}: {
  count: number;
}) => ({
  type: EXLY_MEDIA_LIBRARY__UPDATE_CONCURRENT_UPLOADS_COUNT,
  payload: { count },
});

export const createSetFileUploadErrorAction = (payload: {
  id: string;
  error: string;
}) => ({
  type: EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_ERROR,
  payload,
});

export const createShowVideoUploaderModal = () => ({
  type: EXLY_MEDIA_LIBRARY__SHOW_VIDEO_UPLOADER_MODAL,
});

export const createHideVideoUploaderModal = () => ({
  type: EXLY_MEDIA_LIBRARY__HIDE_VIDEO_UPLOADER_MODAL,
});

export const createRefetchOnUploadSuccessAction = (payload?: {
  // eslint-disable-next-line no-unused-vars
  onSuccess?: (mediaItem: IMediaLibrary.IMedia) => void;
}) => ({
  type: EXLY_MEDIA_LIBRARY__REFETCH_ON_UPLOAD_SUCCESS,
  payload,
});

export const createSetMediaListingsAction = (
  payload: {
    page: number;
    library: IMediaLibrary.IMedia[];
  } | null
) => ({
  type: EXLY_MEDIA_LIBRARY__SET_MEDIA_LISTING,
  payload,
});

export const createUpdateMediaListingAction = (
  payload: Partial<IMediaLibrary.IMedia>
) => ({
  type: EXLY_MEDIA_LIBRARY__UPDATE_MEDIA_LISTING,
  payload,
});

export const createMediaLibraryResetAction = () => ({
  type: EXLY_MEDIA_LIBRARY__RESET,
});
