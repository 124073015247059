import { SET_UP_FUNNEL_KEYS } from "../modules/SetUpFunnel/constants/SetUpFunnel.constants";
import { SET_UP_WEBSITE_KEYS } from "../modules/SetUpWebsite/constants/SetUpWebsite.constants";
import { CONNECT_BANK_ACCOUNT_KEYS } from "../modules/ConnectBankAccount/constants/ConnectBankAccount.constants";
import { GET_LEADS_KEYS } from "../modules/GetLeads/constants/GetLeads.constants";
import {
  checkSetupListKeys,
  isConnectBankAccountCompleted,
  isGetLeadsStepCompleted,
  isSetUpFunnelChecked,
} from "../utils/SetUpCheckList.utils";
import {
  LazyLoadedConnectBankAccount,
  LazyLoadedGetLeads,
  LazyLoadedSetUpFunnel,
  LazyLoadedSetUpWebsite,
} from "./SetUpCheckList.lazy";
import { getOptimisedS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { WHATS_NEXT_ASSETS_URL } from "../../../WhatsNext.constants";

export const SET_UP_LIST_KEYS = {
  SET_UP_FUNNEL_KEYS,
  SET_UP_WEBSITE_KEYS,
  CONNECT_BANK_ACCOUNT_KEYS,
  GET_LEADS_KEYS,
};

export const SET_UP_CHECKLIST_STEPS = {
  SET_UP_FUNNEL: "set_up_funnel",
  SET_UP_WEBSITE: "set_up_your_website",
  CONNECT_BANK_ACCOUNT: "connect_your_bank_account",
  GET_LEADS: "get_leads",
};
export const SET_UP_LIST_CONFIG = [
  {
    id: SET_UP_CHECKLIST_STEPS.SET_UP_FUNNEL,
    title: "Setup your funnel",
    showComponent: LazyLoadedSetUpFunnel,
    checkIsStepCompleted: () => isSetUpFunnelChecked(),
  },
  {
    id: SET_UP_CHECKLIST_STEPS.SET_UP_WEBSITE,
    title: "Setup your website",
    showComponent: LazyLoadedSetUpWebsite,
    checkIsStepCompleted: () =>
      checkSetupListKeys({
        keysArr: SET_UP_LIST_KEYS.SET_UP_WEBSITE_KEYS,
      }),
  },
  {
    id: SET_UP_CHECKLIST_STEPS.CONNECT_BANK_ACCOUNT,
    title: "Connect your bank account",
    showComponent: LazyLoadedConnectBankAccount,
    checkIsStepCompleted: () => isConnectBankAccountCompleted(),
  },
  {
    id: SET_UP_CHECKLIST_STEPS.GET_LEADS,
    title: "Get leads",
    showComponent: LazyLoadedGetLeads,
    checkIsStepCompleted: () => isGetLeadsStepCompleted(),
  },
];

export const SET_UP_LIST_CONFIG_FIRST_ID = SET_UP_LIST_CONFIG[0]?.id;
export const collapse_icon_img = getOptimisedS3AssetCDNUrl(
  `${WHATS_NEXT_ASSETS_URL}/checklist_rtl.svg`
);
