import { video_format_keys } from "features/Common/modules/File/File.constants";

export const accepted_files = Object.values(video_format_keys)
  .map((item) => `.${item}`)
  .join(",");

export const payload_keys = {
  file: "file",
  project_id: "project_id",
};

export const file_input_id = "ExlyWistiaInput";

export const upload_status = {
  uploading: "uploading",
  success: "success",
  failed: "failed",
};

export const max_file_upload_count = 5;

export const result_toast_timeout = 300000; // 5 mins
