import { put, call, select, takeLatest } from "redux-saga/effects";
import { is_empty } from "features/Common/utils/common.utils";
import { isRequestSuccessful } from "utils/Utils";
import { logError } from "utils/error";
import {
  FETCH_QUESTIONS_DATA,
  SET_QUESTIONS_DATA,
  SET_SELECTED_QUESTION_KEY,
} from "./Onboarding.actions";
import { getOnboardingData } from "../utils/Onboarding.utils";
import { SORTED_ONBOARDING_QUESTIONS_KEYS } from "../modules/OnboardingQuestions/constants/OnboardingQuestions.constants";
import { handleOnboardingQuestionsRedirection } from "../modules/OnboardingQuestions/utils/OnboardingQuestions.utils";

function* fetchQuestionsData() {
  try {
    const responses = yield select(
      (state) => state.onboarding.questionResponses
    );
    if (is_empty(responses)) {
      const { data, status } = yield call(getOnboardingData);
      if (isRequestSuccessful(status)) {
        for (const questionKey of SORTED_ONBOARDING_QUESTIONS_KEYS) {
          // check if any key is not present in onboarding_questions response given by api
          if (!(questionKey in data?.onboarding_questions)) {
            yield put({
              type: SET_SELECTED_QUESTION_KEY,
              payload: questionKey,
            });
            handleOnboardingQuestionsRedirection({ key: questionKey });
            break;
          }
        }
        yield put({
          type: SET_QUESTIONS_DATA,
          payload: data?.onboarding_questions,
        });
      }
    }
  } catch (error) {
    logError({
      error,
      when: "calling fetchQuestionsData",
      occuredAt: "src/features/Onboarding/redux/Onboarding.saga.js",
    });
    yield put({
      type: SET_QUESTIONS_DATA,
      payload: {},
    });
  }
}

function* onboardingSaga() {
  yield takeLatest(FETCH_QUESTIONS_DATA, fetchQuestionsData);
}

export default onboardingSaga;
