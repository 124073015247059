import React from "react";
import { Route } from "react-router-dom";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";

const OnboardingThankyouScreen = React.lazy(() =>
  import(
    "features/Onboarding/modules/OnboardingThankyouScreen/OnboardingThankyouScreen"
  )
);

const OnboardingQuestions = React.lazy(() =>
  import("features/Onboarding/modules/OnboardingQuestions/OnboardingQuestions")
);

export const ONBOARDING_FLOW_KEY = "onboarding-questions";

export const ONBOARDING_ROUTE_KEYS = {
  ONBOARDING_THANKYOU_PAGE: "ONBOARDING_THANKYOU_PAGE",
  ONBOARDING_QUESTIONS: "ONBOARDING_QUESTIONS",
};

export const ONBOARDING_ROUTE_IDS = {
  [ONBOARDING_ROUTE_KEYS.ONBOARDING_THANKYOU_PAGE]: "/thankyou",
  [ONBOARDING_ROUTE_KEYS.ONBOARDING_QUESTIONS]: `/${ONBOARDING_FLOW_KEY}/:key`,
};

export const ONBOARDING_PATHNAMES = {
  [ONBOARDING_ROUTE_KEYS.ONBOARDING_QUESTIONS]: (key) =>
    `/${ONBOARDING_FLOW_KEY}/${key}`,
};

export const ONBOARDING_CUSTOM_ROUTES = [
  <Route
    exact
    key={ONBOARDING_ROUTE_IDS[ONBOARDING_ROUTE_KEYS.ONBOARDING_THANKYOU_PAGE]}
    path={ONBOARDING_ROUTE_IDS[ONBOARDING_ROUTE_KEYS.ONBOARDING_THANKYOU_PAGE]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={OnboardingThankyouScreen} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key={`${ONBOARDING_ROUTE_IDS[ONBOARDING_ROUTE_KEYS.ONBOARDING_QUESTIONS]}`}
    path={`${ONBOARDING_ROUTE_IDS[ONBOARDING_ROUTE_KEYS.ONBOARDING_QUESTIONS]}`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={OnboardingQuestions} props={props} />
    )}
    noLayout
  />,
];
