export const UI_LAYOUT__START_TOOL_TIP_TOUR = "@UI_LAYOUT/START_TOOL_TIP_TOUR";
export const UI_LAYOUT__END_TOOL_TIP_TOUR = "@UI_LAYOUT/END_TOOL_TIP_TOUR";
export const UI_LAYOUT__INCREMENT_ACTIVE_TOOL_TIP_INDEX =
  "@UI_LAYOUT/INCREMENT_ACTIVE_TOOL_TIP_INDEX";
export const UI_LAYOUT__DECREMENT_ACTIVE_TOOL_TIP_INDEX =
  "@UI_LAYOUT/DECREMENT_ACTIVE_TOOL_TIP_INDEX";
export const UI_LAYOUT__SET_FILTERED_VISIBILITY_CONFIG =
  "@UI_LAYOUT/SET_FILTERED_VISIBILITY_CONFIG";
export const UI_LAYOUT__SET_HAS_SEEN_TOUR_ONCE =
  "@UI_LAYOUT/SET_HAS_SEEN_TOUR_ONCE";
export const UI_LAYOUT__SET_TOUR_TIP_SUGGESTION_VISIBILITY =
  "@UI_LAYOUT/SET_TOUR_TIP_SUGGESTION_VISIBILITY";
