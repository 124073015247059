import { whats_next_keys } from "features/Onboarding/modules/WhatsNext/WhatsNext.constants";

export const SET_UP_FUNNEL_KEYS = {
  CREATE_EDIT_OFFERINGS: whats_next_keys.has_live_listing.key,
  HAS_CUSTOM_EMAIL: whats_next_keys.has_custom_email.key,
  HAS_WHATSAPP_CAMPAIGN: whats_next_keys.has_whatsapp_campaign.key,
};

export const CREATE_EDIT_OFFERINGS_VIDEO_LINK =
  "https://www.youtube-nocookie.com/watch?v=YPFlt9BP_6I";
