export const EXLY_MEDIA_LIBRARY__SET_MEDIA_LISTING =
  "@EXLY_MEDIA_LIBRARY/SET_MEDIA_LISTING";
export const EXLY_MEDIA_LIBRARY__SET_FILTER = "@EXLY_MEDIA_LIBRARY/SET_FILTER";
export const EXLY_MEDIA_LIBRARY__SET_SEARCH_TERM =
  "@EXLY_MEDIA_LIBRARY/SET_SEARCH_TERM";
export const EXLY_MEDIA_LIBRARY__SET_FILTER_VALUE =
  "@EXLY_MEDIA_LIBRARY/SET_FILTER_VALUE";
export const EXLY_MEDIA_LIBRARY__SET_DRM_ENABLED =
  "@EXLY_MEDIA_LIBRARY/SET_DRM_ENABLED";

export const EXLY_MEDIA_LIBRARY__FETCH_MEDIA_LISTING =
  "@EXLY_MEDIA_LIBRARY/FETCH_MEDIA_LISTING";
export const EXLY_MEDIA_LIBRARY__UPDATE_MEDIA_LISTING =
  "@EXLY_MEDIA_LIBRARY/UPDATE_MEDIA_LISTING";
export const EXLY_MEDIA_LIBRARY__DELETE_MEDIA_LISTING =
  "@EXLY_MEDIA_LIBRARY/DELETE_MEDIA_LISTING";
export const EXLY_MEDIA_LIBRARY__SET_PREVIEW_FILES =
  "@EXLY_MEDIA_LIBRARY/SET_PREVIEW_FILES";
export const EXLY_MEDIA_LIBRARY__SET_UPLOADING =
  "@EXLY_MEDIA_LIBRARY/SET_UPLOADING";
export const EXLY_MEDIA_LIBRARY__SET_SHOW_VIDEO_MODAL =
  "@EXLY_MEDIA_LIBRARY/SET_SHOW_VIDEO_MODAL";
export const EXLY_MEDIA_LIBRARY__RESET = "@EXLY_MEDIA_LIBRARY/RESET";

// uploader action - start
export const EXLY_MEDIA_LIBRARY__ENQUEUE_FILE_UPLOAD =
  "@EXLY_MEDIA_LIBRARY/ENQUEUE_FILE_UPLOAD";
export const EXLY_MEDIA_LIBRARY__REQUEST_FILE_UPLOAD =
  "@EXLY_MEDIA_LIBRARY/REQUEST_FILE_UPLOAD";
export const EXLY_MEDIA_LIBRARY__START_FILE_UPLOAD =
  "@EXLY_MEDIA_LIBRARY/START_FILE_UPLOAD";
export const EXLY_MEDIA_LIBRARY__FILE_UPLOAD_SUCCESS =
  "@EXLY_MEDIA_LIBRARY/FILE_UPLOAD_SUCCESS";
export const EXLY_MEDIA_LIBRARY__FILE_UPLOAD_FAILED =
  "@EXLY_MEDIA_LIBRARY/FILE_UPLOAD_FAILED";
export const EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_PROGRESS =
  "@EXLY_MEDIA_LIBRARY/SET_FILE_UPLOAD_PROGRESS";
export const EXLY_MEDIA_LIBRARY__CANCEL_FILE_UPLOAD =
  "@EXLY_MEDIA_LIBRARY/CANCEL_FILE_UPLOAD";
export const EXLY_MEDIA_LIBRARY__CANCEL_UPLOAD_QUEUE =
  "@EXLY_MEDIA_LIBRARY/CANCEL_UPLOAD_QUEUE";
export const EXLY_MEDIA_LIBRARY__UPDATE_CONCURRENT_UPLOADS_COUNT =
  "@EXLY_MEDIA_LIBRARY/UPDATE_CONCURRENT_UPLOADS_COUNT";
export const EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_ERROR =
  "@EXLY_MEDIA_LIBRARY/SET_FILE_UPLOAD_ERROR";
export const EXLY_MEDIA_LIBRARY__SHOW_VIDEO_UPLOADER_MODAL =
  "@EXLY_MEDIA_LIBRARY/SHOW_VIDEO_UPLOADER_MODAL";
export const EXLY_MEDIA_LIBRARY__HIDE_VIDEO_UPLOADER_MODAL =
  "@EXLY_MEDIA_LIBRARY/HIDE_VIDEO_UPLOADER_MODAL";
export const EXLY_MEDIA_LIBRARY__REFETCH_ON_UPLOAD_SUCCESS =
  "@EXLY_MEDIA_LIBRARY/REFETCH_ON_UPLOAD_SUCCESS";
export const EXLY_MEDIA_LIBRARY__REFETCHED_ON_UPLOAD_SUCCESS =
  "@EXLY_MEDIA_LIBRARY/REFETCHED_ON_UPLOAD_SUCCESS";
// uploader action - end
