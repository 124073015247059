import {
  REDUX_STATE_KEYS,
  UPLOAD_QUEUE_STATUS,
} from "../constants/MediaLibrary.constants";
import {
  EXLY_MEDIA_LIBRARY__CANCEL_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__CANCEL_UPLOAD_QUEUE,
  EXLY_MEDIA_LIBRARY__DELETE_MEDIA_LISTING,
  EXLY_MEDIA_LIBRARY__FETCH_MEDIA_LISTING,
  EXLY_MEDIA_LIBRARY__FILE_UPLOAD_FAILED,
  EXLY_MEDIA_LIBRARY__FILE_UPLOAD_SUCCESS,
  EXLY_MEDIA_LIBRARY__HIDE_VIDEO_UPLOADER_MODAL,
  EXLY_MEDIA_LIBRARY__REFETCH_ON_UPLOAD_SUCCESS,
  EXLY_MEDIA_LIBRARY__REQUEST_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__RESET,
  EXLY_MEDIA_LIBRARY__SET_DRM_ENABLED,
  EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_ERROR,
  EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_PROGRESS,
  EXLY_MEDIA_LIBRARY__SET_FILTER,
  EXLY_MEDIA_LIBRARY__SET_MEDIA_LISTING,
  EXLY_MEDIA_LIBRARY__SET_PREVIEW_FILES,
  EXLY_MEDIA_LIBRARY__SET_SHOW_VIDEO_MODAL,
  EXLY_MEDIA_LIBRARY__SET_UPLOADING,
  EXLY_MEDIA_LIBRARY__SHOW_VIDEO_UPLOADER_MODAL,
  EXLY_MEDIA_LIBRARY__START_FILE_UPLOAD,
  EXLY_MEDIA_LIBRARY__UPDATE_CONCURRENT_UPLOADS_COUNT,
  EXLY_MEDIA_LIBRARY__UPDATE_MEDIA_LISTING,
} from "./MediaLibrary.actions";

const initialState = {
  loading: false,
  mediaListing: [],
  searchTerm: "",
  filterValue: 0,
  filteredMedia: [],
  page: 1,
  totalPages: null,
  is_video_drm_enabled: false,
  previewFiles: [],
  uploading: false,
  showVideoModal: false,
  [REDUX_STATE_KEYS.upload_queue]: [],
  [REDUX_STATE_KEYS.upload_counter]: 0, // The number of files enqueued for upload. This counter is used as the file upload item ID, incrementing with each upload request to ensure a unique ID for every file.
  [REDUX_STATE_KEYS.show_video_uploader_modal]: false,
  [REDUX_STATE_KEYS.refetching_on_upload_success]: false,
  [REDUX_STATE_KEYS.loading_more]: false,
  [REDUX_STATE_KEYS.total]: 0,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_MEDIA_LIBRARY__FETCH_MEDIA_LISTING: {
      return {
        ...state,
        loading: payload.page === 1,
        [REDUX_STATE_KEYS.loading_more]: payload.page !== 1,
        ...payload,
      };
    }
    case EXLY_MEDIA_LIBRARY__SET_MEDIA_LISTING: {
      return {
        ...state,
        loading: false,
        [REDUX_STATE_KEYS.loading_more]: false,
        [REDUX_STATE_KEYS.refetching_on_upload_success]: false,
        mediaListing:
          payload.page === 1
            ? payload?.library
            : [...state?.mediaListing, ...payload?.library],
        totalPages: payload?.total_pages,
        total: payload?.total,
        page: payload.page,
      };
    }

    case EXLY_MEDIA_LIBRARY__UPDATE_MEDIA_LISTING: {
      let objIndex = state?.mediaListing.findIndex(
        (obj) => payload.uuid === obj.uuid
      );

      state.mediaListing[objIndex].title = payload?.title;

      return { ...state };
    }

    case EXLY_MEDIA_LIBRARY__DELETE_MEDIA_LISTING: {
      let objIndex = state?.mediaListing.findIndex(
        (obj) => payload.content_uuid === obj.uuid
      );

      let itemPageNumber = Math.floor(objIndex / 50) + 1;

      if (itemPageNumber === 1) {
        state.mediaListing = [];
      } else {
        let removeFromIndex = 50 * (itemPageNumber - 1);

        state.mediaListing.splice(removeFromIndex);
      }

      return { ...state, pageNumber: itemPageNumber };
    }

    case EXLY_MEDIA_LIBRARY__SET_FILTER: {
      return { ...state, ...payload };
    }

    case EXLY_MEDIA_LIBRARY__SET_PREVIEW_FILES: {
      return { ...state, previewFiles: payload.previewFiles };
    }

    case EXLY_MEDIA_LIBRARY__SET_UPLOADING: {
      return { ...state, uploading: payload.uploading };
    }

    case EXLY_MEDIA_LIBRARY__SET_SHOW_VIDEO_MODAL: {
      return { ...state, showVideoModal: payload.showVideoModal };
    }

    case EXLY_MEDIA_LIBRARY__SET_DRM_ENABLED: {
      return { ...state, is_video_drm_enabled: payload };
    }

    case EXLY_MEDIA_LIBRARY__REQUEST_FILE_UPLOAD: {
      return {
        ...state,
        [REDUX_STATE_KEYS.upload_queue]: [
          ...state[REDUX_STATE_KEYS.upload_queue],
          {
            id: payload.id,
            title: payload.file.name,
            status: payload.status ?? UPLOAD_QUEUE_STATUS.PENDING,
            file: payload.file,
            type: payload.type,
            error: payload.error,
          },
        ],
        [REDUX_STATE_KEYS.upload_counter]: payload.id + 1,
      };
    }

    case EXLY_MEDIA_LIBRARY__START_FILE_UPLOAD: {
      return {
        ...state,
        [REDUX_STATE_KEYS.upload_queue]: state[
          REDUX_STATE_KEYS.upload_queue
        ]?.map((item) =>
          item.id === payload.id
            ? {
                ...item,
                status: UPLOAD_QUEUE_STATUS.UPLOADING,
              }
            : item
        ),
      };
    }

    case EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_PROGRESS: {
      return {
        ...state,
        [REDUX_STATE_KEYS.upload_queue]: state[
          REDUX_STATE_KEYS.upload_queue
        ]?.map((item) =>
          item.id === payload.id
            ? {
                ...item,
                progress: payload.progress,
                timeLeft: payload.timeLeft,
                uploadRate: payload.uploadRate,
                bytesRemaining: payload.bytesRemaining,
              }
            : item
        ),
      };
    }

    case EXLY_MEDIA_LIBRARY__FILE_UPLOAD_SUCCESS: {
      return {
        ...state,
        [REDUX_STATE_KEYS.upload_queue]: state[
          REDUX_STATE_KEYS.upload_queue
        ]?.map((item) =>
          item.id === payload.id
            ? { ...item, status: UPLOAD_QUEUE_STATUS.SUCCESS }
            : item
        ),
      };
    }

    case EXLY_MEDIA_LIBRARY__FILE_UPLOAD_FAILED: {
      return {
        ...state,
        [REDUX_STATE_KEYS.upload_queue]: state[
          REDUX_STATE_KEYS.upload_queue
        ]?.map((item) =>
          item.id === payload.id
            ? { ...item, status: UPLOAD_QUEUE_STATUS.FAILED }
            : item
        ),
      };
    }

    case EXLY_MEDIA_LIBRARY__CANCEL_FILE_UPLOAD: {
      return {
        ...state,
        [REDUX_STATE_KEYS.upload_queue]: state[
          REDUX_STATE_KEYS.upload_queue
        ]?.filter((item) => item.id !== payload.id),
      };
    }

    case EXLY_MEDIA_LIBRARY__CANCEL_UPLOAD_QUEUE: {
      return {
        ...state,
        [REDUX_STATE_KEYS.upload_queue]: [],
      };
    }

    case EXLY_MEDIA_LIBRARY__SET_FILE_UPLOAD_ERROR: {
      return {
        ...state,
        [REDUX_STATE_KEYS.upload_queue]: state[
          REDUX_STATE_KEYS.upload_queue
        ]?.map((item) =>
          item.id === payload.id
            ? {
                ...item,
                status: UPLOAD_QUEUE_STATUS.FAILED,
                error: payload.error,
              }
            : item
        ),
      };
    }

    case EXLY_MEDIA_LIBRARY__SHOW_VIDEO_UPLOADER_MODAL: {
      return {
        ...state,
        [REDUX_STATE_KEYS.show_video_uploader_modal]: true,
      };
    }

    case EXLY_MEDIA_LIBRARY__HIDE_VIDEO_UPLOADER_MODAL: {
      return {
        ...state,
        [REDUX_STATE_KEYS.show_video_uploader_modal]: false,
      };
    }

    case EXLY_MEDIA_LIBRARY__REFETCH_ON_UPLOAD_SUCCESS: {
      return {
        ...state,
        [REDUX_STATE_KEYS.refetching_on_upload_success]: true,
      };
    }

    case EXLY_MEDIA_LIBRARY__UPDATE_CONCURRENT_UPLOADS_COUNT: {
      return { ...state, [REDUX_STATE_KEYS.upload_worker_started]: true };
    }

    case EXLY_MEDIA_LIBRARY__RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
