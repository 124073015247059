import { whats_next_keys } from "features/Onboarding/modules/WhatsNext/WhatsNext.constants";

export const CONNECT_BANK_ACCOUNT_KEYS = {
  DOMESTIC_BANK_ACCOUNT_KEY: whats_next_keys.has_filled_account_details.key,
  INTERNATIONAL_BANK_ACCOUNT_KEY: whats_next_keys.has_stripe_link.key,
};

export const SET_UP_PAYOUTS_VIDEO =
  "https://www.youtube-nocookie.com/watch?v=A7IFHeinnm8";

export const CONNECT_BANK_ACCOUNT_CONFIG = {
  DOMESTIC: {
    key: CONNECT_BANK_ACCOUNT_KEYS.DOMESTIC_BANK_ACCOUNT_KEY,
    heading: "Setup for payouts",
    text: "All your payouts will be processed to this bank account.",
    subText: "Ensure to link correctly!",
    cta: "Connect account",
  },
  INTERNATIONAL: {
    key: CONNECT_BANK_ACCOUNT_KEYS.INTERNATIONAL_BANK_ACCOUNT_KEY,
    heading: "Connect your stripe account",
    text: " Stripe linking will allow you to accept bookings and process payouts!",
    subText: "            So make sure you’re linking the right account!",
    cta: "Connect stripe",
  },
};
