import { dataProvider } from "data";
import { PAYMENT_LINK_APIS } from "features/PaymentLinks/constants/PaymentLinks.apis";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { IPaymentLinkApiPayload } from "../interfaces/IPaymentLinkApiPayload";

export const createPaymentLink = async (payload: IPaymentLinkApiPayload) => {
  const { data, status } = await dataProvider.custom_request(
    PAYMENT_LINK_APIS.generate_payment_link,
    apiMethods.POST,
    payload
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error creating payment link: ${data} ${status}`);

  const {
    pricing_link: pricingLink,
    listing_uuid: listingUuid,
    dynamic_link_id: dynamicLinkUuid,
  } = data;

  return { pricingLink, listingUuid, dynamicLinkUuid };
};

export const updatePaymentLink = async (
  paymentLinkId: string,
  payload: Pick<IPaymentLinkApiPayload, "title" | "expiry_date">
) => {
  const { data, status } = await dataProvider.custom_request(
    PAYMENT_LINK_APIS.update_payment_link,
    apiMethods.POST,
    { dynamic_link_id: paymentLinkId, updated_values: payload }
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error updating payment link: ${data} ${status}`);
};
