import {
  UI_LAYOUT__END_TOOL_TIP_TOUR,
  UI_LAYOUT__START_TOOL_TIP_TOUR,
  UI_LAYOUT__INCREMENT_ACTIVE_TOOL_TIP_INDEX,
  UI_LAYOUT__SET_FILTERED_VISIBILITY_CONFIG,
  UI_LAYOUT__SET_HAS_SEEN_TOUR_ONCE,
  UI_LAYOUT__SET_TOUR_TIP_SUGGESTION_VISIBILITY,
  UI_LAYOUT__DECREMENT_ACTIVE_TOOL_TIP_INDEX,
} from "./UILayout.actions";

const initialState = {
  isToolTipTourActive: false,
  activeTooltipIndex: null,
  visibleConfig: [],
  hasSeenCtTour: true,
  showTourSuggestion: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UI_LAYOUT__START_TOOL_TIP_TOUR: {
      return {
        ...state,
        isToolTipTourActive: true,
        activeTooltipIndex: 0,
      };
    }

    case UI_LAYOUT__END_TOOL_TIP_TOUR: {
      return {
        ...state,
        isToolTipTourActive: false,
        activeTooltipIndex: null,
        showTourSuggestion: !state.hasSeenCtTour,
        hasSeenCtTour: true,
      };
    }

    case UI_LAYOUT__SET_TOUR_TIP_SUGGESTION_VISIBILITY: {
      return {
        ...state,
        showTourSuggestion: payload,
      };
    }

    case UI_LAYOUT__SET_HAS_SEEN_TOUR_ONCE: {
      return {
        ...state,
        hasSeenCtTour: payload,
      };
    }

    case UI_LAYOUT__INCREMENT_ACTIVE_TOOL_TIP_INDEX: {
      return {
        ...state,
        activeTooltipIndex: state.activeTooltipIndex + 1,
      };
    }

    case UI_LAYOUT__DECREMENT_ACTIVE_TOOL_TIP_INDEX: {
      return {
        ...state,
        activeTooltipIndex: state.activeTooltipIndex - 1,
      };
    }

    case UI_LAYOUT__SET_FILTERED_VISIBILITY_CONFIG: {
      return {
        ...state,
        visibleConfig: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
