import { is_empty } from "features/Common/utils/common.utils";
import {
  SET_UP_CHECKLIST_STEPS,
  SET_UP_LIST_CONFIG,
  SET_UP_LIST_KEYS,
} from "../constants/SetUpCheckList.constants";
import { getWhatsNextInfo } from "features/Onboarding/modules/WhatsNext/utils/whatsNext.utils";
import { SET_UP_FUNNEL_KEYS } from "../modules/SetUpFunnel/constants/SetUpFunnel.constants";
import { isInternationalTimezone } from "utils/AuthUtil";
import { CONNECT_BANK_ACCOUNT_KEYS } from "../modules/ConnectBankAccount/constants/ConnectBankAccount.constants";

/**
 * Checks if all keys in the provided array exist in the `nextSteps` object and are `true`.
 *
 * @function
 * @param {Object} params - The parameters object.
 * @param {Array<string>} params.keysArr - An array of keys to be checked within the `nextSteps` object.
 *
 * @returns {boolean} - Returns `true` if all keys in `keysArr` are present in the `nextSteps` object and are `true`.
 *   Otherwise, returns `false`.
 */
export const checkSetupListKeys = ({ keysArr }) => {
  const nextSteps = getWhatsNextInfo();
  if (is_empty(keysArr)) return false;
  // Loop over each key-value pair in the keysArr object
  for (const key in keysArr) {
    // Get the corresponding key in nextSteps object
    const nextStepsKey = keysArr[key];

    // Check if the key is present in the nextSteps object and is `true`
    if (!nextSteps?.[nextStepsKey]) {
      return false; // If any key is not `true`, return false
    }
  }
  return true; // If all keys are `true`, return true
};

/**
 * Finds the first configuration item in the `SET_UP_LIST_CONFIG` where the corresponding keys in `nextSteps` are not all `true`.
 *
 * @function
 * @param {Object} params - The parameters object.
 * @param {Object} params.nextSteps - An object representing the setup steps, where each key corresponds to a setup step.
 *
 * @returns {string|null} - Returns the `id` of the first incomplete setup configuration found in `SET_UP_LIST_CONFIG`.
 *   Returns `null` if all configurations are complete.
 */
export const findFirstIncompleteConfig = () => {
  for (const config of SET_UP_LIST_CONFIG) {
    if (!config.checkIsStepCompleted()) {
      return config.id; // Return the first id where keys are not true
    }
  }
  return null; // Return null if all keys are true in every config
};

/**
 * Checks if the setup funnel step is completed based on specific conditions in the `nextSteps` object.
 *
 * @function
 * @returns {boolean} - Returns `true` if the setup funnel step is considered completed, otherwise `false`.
 */
export const isSetUpFunnelChecked = () => {
  const nextSteps = getWhatsNextInfo();
  return (
    (nextSteps[SET_UP_FUNNEL_KEYS.HAS_CUSTOM_EMAIL] ||
      nextSteps[SET_UP_FUNNEL_KEYS.HAS_WHATSAPP_CAMPAIGN]) &&
    nextSteps[SET_UP_FUNNEL_KEYS.CREATE_EDIT_OFFERINGS]
  );
};

export const isConnectBankAccountCompleted = () => {
  const nextSteps = getWhatsNextInfo();
  const isIndian = !isInternationalTimezone();
  return isIndian
    ? nextSteps[CONNECT_BANK_ACCOUNT_KEYS.DOMESTIC_BANK_ACCOUNT_KEY]
    : nextSteps[CONNECT_BANK_ACCOUNT_KEYS.INTERNATIONAL_BANK_ACCOUNT_KEY];
};

/**
 * Checks if the "Get Leads" setup step is completed based on the user's time zone.
 *
 * @function isGetLeadsStepCompleted
 * @returns {boolean} - Returns `true` if the required setup keys for "Get Leads" are completed, `false` otherwise.
 *
 * @description
 * This function evaluates whether the "Get Leads" step in the setup list is completed.
 * It checks the user's time zone to determine if they are in an international time zone.
 * If the user is international, the key `HAS_RUN_ADS` is removed from the array of required keys.
 * The modified keys array is then passed to `checkSetupListKeys` to determine if all necessary setup steps are completed.
 */
export const isGetLeadsStepCompleted = () => {
  const isInternational = isInternationalTimezone();
  const keysArr = SET_UP_LIST_KEYS.GET_LEADS_KEYS;
  if (isInternational) {
    delete keysArr.HAS_RUN_ADS;
  }
  return checkSetupListKeys({
    keysArr,
  });
};

/**
 * Checks if the setup step corresponding to the provided `stepId` is completed by evaluating its completion function.
 *
 * @function
 * @param {string} stepId - The ID of the setup step to check.
 * @returns {boolean} - Returns `true` if the setup step is completed, otherwise `false`.
 */
const checkStepById = (stepId) => {
  const step = SET_UP_LIST_CONFIG.find((step) => step.id === stepId);
  return step ? step.checkIsStepCompleted() : false;
};

/**
 * Checks if the initial checklist, consisting of the first three setup steps (funnel, website, bank account), is fully completed.
 *
 * @function
 * @returns {boolean} - Returns `true` if all three initial setup steps are completed, otherwise `false`.
 */
export const isInitialChecklistCompleted = () => {
  return (
    checkStepById(SET_UP_CHECKLIST_STEPS.SET_UP_FUNNEL) && // Check "Setup your funnel"
    checkStepById(SET_UP_CHECKLIST_STEPS.SET_UP_WEBSITE) && // Check "Setup your website"
    checkStepById(SET_UP_CHECKLIST_STEPS.CONNECT_BANK_ACCOUNT) // Check "Connect your bank account"
  );
};
