export const OFFERING_INVOICE_MANAGEMENT_URL_KEYS = {
  offering_invoice_management: "offering_invoice_management",
};

export const OFFERING_INVOICE_MANAGEMENT_ROUTE_IDS = {
  [OFFERING_INVOICE_MANAGEMENT_URL_KEYS.offering_invoice_management]:
    "/offering_invoice_management/:listing_uuid",
};

export const OFFERING_INVOICE_MANAGEMENT_PATH_NAMES = {
  [OFFERING_INVOICE_MANAGEMENT_URL_KEYS.offering_invoice_management]:
    "/offering_invoice_management",
};
