import React from "react";

export const LazyLoadedSetUpFunnel = React.lazy(() =>
  import("../modules/SetUpFunnel/SetUpFunnel").then((module) => ({
    default: module.SetUpFunnel,
  }))
);

export const LazyLoadedSetUpWebsite = React.lazy(() =>
  import("../modules/SetUpWebsite/SetUpWebsite").then((module) => ({
    default: module.SetUpWebsite,
  }))
);

export const LazyLoadedConnectBankAccount = React.lazy(() =>
  import("../modules/ConnectBankAccount/ConnectBankAccount").then((module) => ({
    default: module.ConnectBankAccount,
  }))
);

export const LazyLoadedGetLeads = React.lazy(() =>
  import("../modules/GetLeads/GetLeads").then((module) => ({
    default: module.GetLeads,
  }))
);
