import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";
import api from "data/APIs";
import dataProvider from "data/dataProvider";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { createMediaLibraryFetchListingAction } from "./MediaLibrary.actionCreators";
import {
  EXLY_MEDIA_LIBRARY__FETCH_MEDIA_LISTING,
  EXLY_MEDIA_LIBRARY__SET_FILTER,
  EXLY_MEDIA_LIBRARY__SET_MEDIA_LISTING,
} from "./MediaLibrary.actions";
import {
  enqueueFileUpload,
  mediaLibFileUploadSaga,
  watchUpdateConcurrentUploadsCount,
} from "./sagas/MediaLibraryUploader.saga";

function* getListingData(action) {
  const { payload } = action || {};

  const { is_drm_flow, page = 1, search, type } = payload;

  const params = { is_drm_flow, page, search, type };

  try {
    const is_video_drm_enabled = yield select(
      (state) => state.mediaLibrary.is_video_drm_enabled
    );

    const data = yield call(
      dataProvider.custom_request,
      `${api.media_library.get_media_library}`,
      "GET",
      { ...params, is_drm_flow: is_video_drm_enabled }
    );
    if (isRequestSuccessful(data?.status)) {
      yield put({
        type: EXLY_MEDIA_LIBRARY__SET_MEDIA_LISTING,
        payload: { page: params.page, ...data?.data },
      });
    }
  } catch (err) {
    yield put({ type: EXLY_MEDIA_LIBRARY__SET_MEDIA_LISTING, payload: null });
  }
}

function* mediaLibraryFilterSaga() {
  yield takeEvery(EXLY_MEDIA_LIBRARY__SET_FILTER, function* (action) {
    yield put(
      createMediaLibraryFetchListingAction({ page: 1, ...action.payload })
    );
  });
}

function* mediaLibrarySaga() {
  yield takeEvery(EXLY_MEDIA_LIBRARY__FETCH_MEDIA_LISTING, getListingData);
}

export default function* rootSaga() {
  yield all([
    mediaLibrarySaga(),
    mediaLibraryFilterSaga(),
    enqueueFileUpload(),
    watchUpdateConcurrentUploadsCount(),
    mediaLibFileUploadSaga(),
  ]);
}
