import { RECORDED_CONTENT_VIDEO_SOURCE } from "@my-scoot/exly-react-component-lib";
import constants from "constants/constants";
import { dataProvider } from "data";
import { apiMethods, HTTP_STATUS_SUCCESS_CODES } from "data/api.constants";
import { removeExtensionFromFileName } from "features/Common/modules/File/utils/File";
import {
  VDOCIPHER_VIDEO_MAX_FILE_SIZE_IN_GB,
  WISTIA_VIDEO_MAX_FILE_SIZE_IN_GB,
} from "features/RecordedContent/RecordedContent.constants";
import { isFileMimeTypeImage } from "schedule-v2/modals/recorded-content/AddImageModal/AddImageModal.helpers";
import { constants_media } from "ui/pages/MediaLibrary/constants";
import { getFileSize, isVideoFile } from "utils/fileUtils";
import {
  getMediaTypeFromMIMEType,
  getPrivateS3Bucket,
  getS3BucketForRecordedContent,
  getS3KeyForRecordedContent,
} from "utils/recordedContentUtils/recordedContentUtils";
import { getS3SignedUrl, isRequestSuccessful, uploadToS3 } from "utils/Utils";
import { MEDIA_LIBRARY_APIS } from "../constants/MediaLibrary.constants";
import { IMediaLibrary } from "../MediaLibrary.interfaces";
import {
  WISTIA_EMBED_BASE_URL,
  WISTIA_UPLOAD_API,
} from "../modules/VideoUploader/modules/ExlyWistia/constants/ExlyWistia.apis";
import { payload_keys } from "../modules/VideoUploader/modules/ExlyWistia/constants/ExlyWistia.constants";
import { getFileMimeType, getWistiaHashIdFromLink } from "./MediaLibrary.utils";

export const deleteWistiaVideo = async (url: string) => {
  const wistiaKey = getWistiaHashIdFromLink(url);
  const response = await fetch(
    `https://api.wistia.com/v1/medias/${wistiaKey}.json`,
    {
      method: apiMethods.DELETE,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_WISTIA_TOKEN}`,
      },
    }
  );
  await response?.json();
};

export const getVdocipherDownloadUrl = async (
  media: Partial<IMediaLibrary.IMedia>
) => {
  const { uuid } = media;
  const { data, status } = await dataProvider.custom_request(
    MEDIA_LIBRARY_APIS.vdocipher.download_url,
    apiMethods.GET,
    { content_uuid: uuid }
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error fetching download url: ${data} ${status}`);

  const { redirect } = data;

  return redirect;
};

export const getIsMediaTypeExists = async (mediaType: number) => {
  const { data, status } = await dataProvider.custom_request(
    `${MEDIA_LIBRARY_APIS.media_type_existency}/${mediaType}`,
    apiMethods.GET
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error fetching media type existency: ${data} ${status}`);

  const { exists } = data;

  return exists;
};

export const addMedia = async (payload: Partial<IMediaLibrary.IMedia>) => {
  const { data, status } = await dataProvider.custom_request(
    MEDIA_LIBRARY_APIS.add_media,
    apiMethods.POST,
    payload
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error adding media to media library: ${data} ${status}`);

  return data;
};

export const updateMedia = async ({
  uuid,
  ...payload
}: Partial<IMediaLibrary.IMedia> & { status: number }) => {
  const { data, status } = await dataProvider.custom_request(
    MEDIA_LIBRARY_APIS.update_media,
    apiMethods.POST,
    { content_uuid: uuid, updated_values: payload }
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error updating media: ${data} ${status}`);
};

export const bulkDeleteMedia = async (
  mediaItemUuids: IMediaLibrary.IMedia["uuid"][]
) => {
  const { data, status } = await dataProvider.custom_request(
    MEDIA_LIBRARY_APIS.bulk_delete,
    apiMethods.POST,
    { content_uuids: mediaItemUuids }
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error deleting media: ${data} ${status}`);
};

export const getPreviewableUrl = async (url: string) => {
  if (!url) throw new Error("Please provide content url");

  const privateBucket = getPrivateS3Bucket(url);

  const previewableUrl = await getS3SignedUrl(
    url.split("amazonaws.com/")[1],
    privateBucket,
    constants.RECORDED_CONTENT_UPLOAD_AWS_CRED
  );

  return previewableUrl;
};

export const uploadWistiaVideo: IMediaLibrary.IMediaUploader = async ({
  file,
  title,
  abortSignal,

  onProgress,
  onError,
  onSuccess,
  onAbort,
}) => {
  try {
    if (!isVideoFile(file)) throw new Error("Please Pick a Video!");

    const { fileSizeInGB } = getFileSize(file.size);

    if (+fileSizeInGB > WISTIA_VIDEO_MAX_FILE_SIZE_IN_GB)
      throw new Error(
        `Can’t upload. This video exceeds the maximum allowed size limit: ${WISTIA_VIDEO_MAX_FILE_SIZE_IN_GB} GB. Please compress the file and retry`
      );

    const xhr = new XMLHttpRequest();

    xhr.open(apiMethods.POST, WISTIA_UPLOAD_API);
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${process.env.REACT_APP_WISTIA_TOKEN}`
    );

    if (onProgress) xhr.upload.addEventListener("progress", onProgress);

    if (onError) xhr.addEventListener("error", (event) => onError(event));

    if (onAbort) xhr.addEventListener("abort", onAbort);

    if (abortSignal) abortSignal.addEventListener("abort", () => xhr.abort());

    if (onSuccess) {
      xhr.onreadystatechange = async () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          const status = xhr.status;
          const jsonResonse = xhr.response ? JSON.parse(xhr.response) : {};
          const { detail, hashed_id } = jsonResonse;
          /**
           * we have to specify this success status code in form data
           * we'll get the same status code on successful video upload (refer to form data below)
           * NOTE: change status code at both places if required
           */
          if (isRequestSuccessful(status)) {
            try {
              const url = `${WISTIA_EMBED_BASE_URL}/${hashed_id}`;
              const mediaLibraryContentPayload = {
                content_url: url,
                title,
                type: constants_media.video_type,
                video_source: RECORDED_CONTENT_VIDEO_SOURCE.WISTIA,
                metadata: {
                  mime_type: getFileMimeType(file),
                  file_size: file.size,
                },
              };

              const { library_id: libraryId, uuid } = await addMedia(
                mediaLibraryContentPayload
              );

              if (onSuccess)
                onSuccess({
                  mediaLibraryContentPayload: {
                    ...mediaLibraryContentPayload,
                    uuid,
                    id: libraryId,
                  },
                  url,
                });
            } catch (error) {
              if (onError) onError(new Error(detail));
            }
          } else {
            if (onError) onError(new Error(detail));
          }
        }
      };
    }

    const formData = new FormData();
    formData.append(payload_keys.file, file);
    formData.append(
      payload_keys.project_id,
      process.env.REACT_APP_WISTIA_PROJECT_ID!
    );

    xhr.send(formData);
  } catch (error) {
    if (onError) onError(error);
  }
};

const fetchVdoCipherVideoUploadCreds = async ({
  title,
  listingUuid,
}: {
  title: string;
  listingUuid?: string;
}) => {
  const data = await dataProvider.custom_request(
    MEDIA_LIBRARY_APIS.vdocipher.upload_creds,
    apiMethods.POST,
    {
      title,
      listing_uuid: listingUuid,
    }
  );
  return data.data;
};

export const uploadVdocipherNonDrmVideo: IMediaLibrary.IMediaUploader = async ({
  file,
  title,
  listingUuid,
  abortSignal,

  onProgress,
  onError,
  onSuccess,
  onAbort,
}) => {
  try {
    if (!isVideoFile(file)) throw new Error("Please Pick a Video!");

    const { fileSizeInGB } = getFileSize(file.size);

    if (+fileSizeInGB > VDOCIPHER_VIDEO_MAX_FILE_SIZE_IN_GB)
      throw new Error(
        `Can’t upload. This video exceeds the maximum allowed size limit: ${VDOCIPHER_VIDEO_MAX_FILE_SIZE_IN_GB} GB. Please compress the file and retry`
      );

    const creds = await fetchVdoCipherVideoUploadCreds({ title, listingUuid });
    const {
      clientPayload: { uploadLink, ...restClientPayload },
      videoId,
    } = creds;

    const xhr = new XMLHttpRequest();
    xhr.open(apiMethods.POST, uploadLink, true);

    if (onProgress) xhr.upload.addEventListener("progress", onProgress);

    if (onError) xhr.addEventListener("error", onError);

    if (onAbort) xhr.addEventListener("abort", onAbort);

    if (abortSignal) abortSignal.addEventListener("abort", () => xhr.abort());

    if (onSuccess) {
      xhr.onreadystatechange = async (event) => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          const status = xhr.status;
          /**
           * we have to specify this success status code in form data
           * we'll get the same status code on successful video upload (refer to form data below)
           * NOTE: change status code at both places if required
           */
          if (status === HTTP_STATUS_SUCCESS_CODES.HTTP_STATUS_CREATED) {
            try {
              const mediaLibraryContentPayload = {
                content_url: videoId,
                title,
                type: constants_media.video_type,
                video_source: RECORDED_CONTENT_VIDEO_SOURCE.VDOCIPHER_NON_DRM,
                metadata: {
                  mime_type: getFileMimeType(file),
                  file_size: file.size,
                },
              };

              const { library_id: libraryId, uuid } = await addMedia(
                mediaLibraryContentPayload
              );

              if (onSuccess)
                onSuccess({
                  mediaLibraryContentPayload: {
                    ...mediaLibraryContentPayload,
                    uuid,
                    id: libraryId,
                  },
                  videoId,
                });
            } catch (error) {
              if (onError) onError(event);
            }
          } else {
            if (onError) onError(event);
          }
        }
      };
    }

    const formData = new FormData();

    // order is important here
    // ref: https://www.vdocipher.com/docs/server/upload/credentials
    const formDataBackendRequiredKeys = [
      "policy",
      "key",
      "x-amz-signature",
      "x-amz-algorithm",
      "x-amz-date",
      "x-amz-credential",
    ];
    formDataBackendRequiredKeys.forEach((key) =>
      formData.append(key, restClientPayload[key])
    );

    // keys from front-end
    formData.append(
      "success_action_status",
      `${HTTP_STATUS_SUCCESS_CODES.HTTP_STATUS_CREATED}`
    ); // mention custom success action status code (this status code will be returned in success response i.e., video uploaded successfully)
    formData.append("success_action_redirect", ""); // mention link to redirect on success
    formData.append("file", file);

    xhr.send(formData);
  } catch (error) {
    if (onError) onError(error);
  }
};

export const uploadImageOrDocFile: IMediaLibrary.IMediaUploader = async ({
  file,
  abortSignal,

  onProgress,
  onError,
  onSuccess,
  onAbort,
}) => {
  uploadToS3({
    file,
    key: getS3KeyForRecordedContent(file),
    bucket: getS3BucketForRecordedContent(file),
    AWSConfig: constants.RECORDED_CONTENT_UPLOAD_AWS_CRED,
    contentType: file.type,
    abortSignal,
    onProgress,
    onAbort,
  })
    .then(async (uploadToS3ApiResponse) => {
      const fileLocation = uploadToS3ApiResponse?.Location;
      if (fileLocation) {
        let fileName = file.name || "";
        const fileType = file.type;
        /**
         * Asked By Product Team to remove extension from fileName in just images.
         */
        if (isFileMimeTypeImage(fileType))
          fileName = removeExtensionFromFileName(fileName);

        const mediaLibraryContentPayload = {
          content_url: fileLocation,
          title: fileName,
          type: getMediaTypeFromMIMEType(fileType)!,
          metadata: { mime_type: fileType, file_size: file.size },
        };

        const { library_id: libraryId, uuid } = await addMedia(
          mediaLibraryContentPayload
        );

        if (onSuccess)
          onSuccess({
            mediaLibraryContentPayload: {
              ...mediaLibraryContentPayload,
              uuid,
              id: libraryId,
            },
            fileLocation,
          });
      } else {
        if (onError)
          onError(
            new Error(
              `uploadToS3ApiResponse.Location not found. uploadToS3ApiResponse: ${JSON.stringify(
                uploadToS3ApiResponse
              )};`
            )
          );
      }
    })
    .catch(onError);
};
