import React from "react";
import {
  first_offerings_selection_option,
  shuffled_offerings_selection_options,
  offerings_selection_wise_info_config,
} from "../modules/SkuSelection/constants/SkuSelection.constants";
import {
  first_sales_marketing_suite_option,
  sales_marketing_suites_options,
  sales_marketing_suites_wise_info_config,
} from "../modules/MarketingSuite/constants/MarketingSuite.constant";

// Lazy loading components using React.lazy
const CreativeCategory = React.lazy(() =>
  import("../modules/CreativeCategory/CreativeCategory")
);
const TargetRevenue = React.lazy(() =>
  import("../modules/TargetRevenue/TargetRevenue")
);
const RightBanner = React.lazy(() =>
  import("../modules/RightBanner/RightBanner")
);
const SkuSelection = React.lazy(() =>
  import("../modules/SkuSelection/SkuSelection")
);
const OnboardingInfoRightWrapper = React.lazy(() =>
  import("../modules/OnboardingInfoRightWrapper/OnboardingInfoRightWrapper")
);
const MarketingSuite = React.lazy(() =>
  import("../modules/MarketingSuite/MarketingSuite")
);

// ONBOARDING_QUESTIONS are the questions asked in onboarding flow, priority is used to sort the screen sequences
export const ONBOARDING_QUESTIONS = {
  CREATOR_CATEGORY: {
    key: "CREATOR_CATEGORY",
    priority: 1,
    route_key: "creator-category",
  },
  TARGET_REVENUE: {
    key: "TARGET_REVENUE",
    priority: 2,
    route_key: "target-revenue",
  },
  OFFERINGS_SELECTION: {
    key: "OFFERINGS_SELECTION",
    priority: 3,
    route_key: "offerings-selection",
  },
  SALES_MARKETING_SUITE: {
    key: "SALES_MARKETING_SUITE",
    priority: 4,
    route_key: "sales-marketing-suite",
  },
};

// SORTED_ONBOARDING_QUESTIONS_KEYS is an array with questions keys in sorted order
export const SORTED_ONBOARDING_QUESTIONS_KEYS = Object.entries(
  ONBOARDING_QUESTIONS
)
  .sort(([, a], [, b]) => a.priority - b.priority)
  .map(([, value]) => value.key);

export const [FIRST_ONBOARDING_QUESTION_KEY] = SORTED_ONBOARDING_QUESTIONS_KEYS;

export const heading_string = "Tell us about your business";

// This config is used to render the children components based on question key
export const ONBOARDING_QUESTIONS_CONFIG = {
  [ONBOARDING_QUESTIONS.CREATOR_CATEGORY.key]: {
    title: heading_string,
    id: ONBOARDING_QUESTIONS.CREATOR_CATEGORY.priority,
    questionComponent: CreativeCategory,
    rightComponent: RightBanner,
  },
  [ONBOARDING_QUESTIONS.TARGET_REVENUE.key]: {
    title: heading_string,
    id: ONBOARDING_QUESTIONS.TARGET_REVENUE.priority,
    questionComponent: TargetRevenue,
    rightComponent: RightBanner,
  },
  [ONBOARDING_QUESTIONS.OFFERINGS_SELECTION.key]: {
    title: heading_string,
    id: ONBOARDING_QUESTIONS.OFFERINGS_SELECTION.priority,
    questionComponent: SkuSelection,
    rightComponent: OnboardingInfoRightWrapper,
    rightConfig: {
      config: offerings_selection_wise_info_config,
      options: shuffled_offerings_selection_options,
      defaultValue: first_offerings_selection_option.value,
    },
  },
  [ONBOARDING_QUESTIONS.SALES_MARKETING_SUITE.key]: {
    title: heading_string,
    id: ONBOARDING_QUESTIONS.SALES_MARKETING_SUITE.priority,
    questionComponent: MarketingSuite,
    rightComponent: OnboardingInfoRightWrapper,
    rightConfig: {
      config: sales_marketing_suites_wise_info_config,
      options: sales_marketing_suites_options,
      defaultValue: first_sales_marketing_suite_option.value,
    },
  },
};

export const location_key = "location";
export const SCROLLABLE_ID = "scrollableDiv";
export const FLOATER_POSITION_ON_SCROLL_THROTTLE_TIME = 100;
