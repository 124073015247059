export const colomnKeys = {
  title: {
    label: "Variation title",
    key: "title",
  },

  price: {
    label: "Price",
    domestic_price: "domestic_price",
    international_price: "international_price",
  },

  subscription_plan: {
    label: "Subscription plan",
    choice: "subscription_plan_choice",
    magnitude: "subscription_plan_magnitude",
  },

  type: {
    label: "Type",
    key: "variant_type",
  },

  action: {
    label: "Actions",
  },
};

export const OFFERING_VARIATIONS_LEARN_MORE_HREFS = {
  OFFERING_VARTIATION:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392257-how-to-create-variations-of-your-offering-",
};
