import { dataProvider } from "data";
import { GET_OFFERING_VARIATIONS } from "../OfferingVariations.apis";
import { apiMethods } from "data/api.constants";
import { COURSE_END_CHOICE_ENUM, schedule_types_ids } from "constants/schedule";
import { is_empty } from "utils/validations";
import { colomnKeys } from "../pages/OfferingVariationsList/OfferingVariationsList.constants";

export const fetchOfferingVariations = ({ offeringUuid }) => {
  return dataProvider.custom_request(GET_OFFERING_VARIATIONS, apiMethods.GET, {
    listing_uuid: offeringUuid,
    page: "all",
  });
};

export const canShowOfferingVariations = (offering) => {
  if (is_empty(offering)) return false;
  if (offering.type === schedule_types_ids.rolling_class) {
    // for flexible class
    return true;
  }
  if (
    offering.type === schedule_types_ids.no_schedule && // for qpp subscription
    !is_empty(offering.course_end_magnitude) &&
    offering.course_end_magnitude > 0
  ) {
    return true;
  }
  return false;
};

export const getSubscriptionPlanChoiceLabel = ({
  offeringDetails,
  selectedRecord,
}) => {
  if (
    is_empty(offeringDetails) ||
    is_empty(selectedRecord?.[colomnKeys.subscription_plan.choice])
  )
    return;
  return COURSE_END_CHOICE_ENUM[
    selectedRecord[colomnKeys.subscription_plan.choice]
  ][
    offeringDetails?.type === schedule_types_ids.rolling_class &&
    COURSE_END_CHOICE_ENUM[selectedRecord[colomnKeys.subscription_plan.choice]]
      .sessionLabel
      ? "sessionLabel"
      : "label"
  ];
};
