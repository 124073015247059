import moment from "moment";
import React from "react";
import { handleDateFormatV2 } from "utils/Utils";
import { isFloat } from "utils/validations";
import { LINK_TYPE_LABELS } from "../../CreatePaymentLinkModal/constants/CreatePaymentLinkModal.constants";
import LinkField from "../components/LinkField/LinkField";
import MoneyField from "../components/MoneyField/MoneyField";
import {
  EXISTING_PAYMENT_LINKS_COLUMN_KEYS,
  EXISTING_PAYMENT_LINKS_FILTER_KEYS,
} from "../constants/ExistingPaymentLinks.constants";
import { IExistingPaymentLinks } from "../interfaces/IExistingPaymentLinks";
import TotalBookingsField from "../components/TotalBookingsField/TotalBookingsField";

export const formatLinkType = (
  record: IExistingPaymentLinks.IExistingPaymentLink
) => {
  if (!record) return "N/A";

  const { payment_link_type: linkType } = record;
  return LINK_TYPE_LABELS[linkType] ?? "N/A";
};

export const formatLinkDate =
  (source: keyof typeof EXISTING_PAYMENT_LINKS_COLUMN_KEYS) =>
  (record: IExistingPaymentLinks.IExistingPaymentLink) => {
    if (!record) return "N/A";

    const { [source]: date } = record;

    return date ? handleDateFormatV2(date) : "N/A";
  };

export const formatPaymentLink = (
  record: IExistingPaymentLinks.IExistingPaymentLink
) => <LinkField link={record.link} />;

export const formatLinkPrice = (
  source: keyof typeof EXISTING_PAYMENT_LINKS_COLUMN_KEYS
) => {
  const RenderComponent = (
    record: IExistingPaymentLinks.IExistingPaymentLink
  ) => {
    return isFloat(record[source]) ? (
      <MoneyField record={record} source={source} />
    ) : (
      "N/A"
    );
  };

  return RenderComponent;
};

export const formatTotalBookings = (
  record: IExistingPaymentLinks.IExistingPaymentLink
) => {
  return record.total_bookings ? (
    <TotalBookingsField
      dynamicLinkUuid={record.dynamic_link_id}
      bookingsCount={record.total_bookings}
    />
  ) : (
    "0"
  );
};

export const existingPaymentLinksFilterChipsFormatter = (
  filter: string,
  value: number | string
) => {
  switch (filter) {
    case "listing__title__icontains":
      return `Offering Name: ${value}`;
    case EXISTING_PAYMENT_LINKS_FILTER_KEYS.created_at__start_range:
      return `Date created: ${moment(value).format("MMM DD, YYYY")}`;
    case EXISTING_PAYMENT_LINKS_FILTER_KEYS.expiry_date__start_range:
      return `Expiry date: ${moment(value).format("MMM DD, YYYY")}`;
    case EXISTING_PAYMENT_LINKS_FILTER_KEYS.payment_link_type:
      return `Link type: ${LINK_TYPE_LABELS[value as number]}`;
    default:
      return undefined;
  }
};
