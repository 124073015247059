import {
  getTransactionAmountConfig,
  getTransactionBillingStateConfig,
  getTransactionCustomerEmailConfig,
  getTransactionCustomerInvoiceConfig,
  getTransactionCustomerNameConfig,
  getTransactionCustomerPhoneConfig,
  getTransactionDateConfig,
  getTransactionDebitTypeConfig,
  getTransactionGSTInfoConfig,
  getTransactionInternationalConfig,
  getTransactionOfferingNameConfig,
  getTransactionOfferingOwnerConfig,
  getTransactionOfferingTypeConfig,
  getTransactionPaymentAmountConfig,
  getTransactionPaymentModeConfig,
  getTransactionPaymentTypeConfig,
  getTransactionPayoutDateConfig,
  getTransactionPayoutStatusConfig,
  getTransactionPricePerSlotConfig,
  getTransactionTransactionIdConfig,
  getTransactionTypeConfig,
  getTransactionUnitsConfig,
  getTransactionUTRInfoConfig,
} from "features/Crm/modules/Transactions/utils/Transactions.tableConfigs";
import {
  ALL_TRANSACTIONS_TABLE_FILTER_CHIPS_FORMATTERS,
  filterConfig,
} from "ui/pages/transactions/AllTransaction/AllTransactionTableConfig";
import {
  formatInternationalTransactionType,
  formPaymentlinkType,
  getIsInternationalTransactionFilterConfig,
  getTransactionPaymentLinkConfig,
  getTransactionPaymentLinkTypeConfig,
  getTransactionsSourceFilterConfig,
} from "../utils/PaymentLinksTransactions.utils";

export const PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS = {
  PAYMENT_LINKS_TRANSACTIONS: "payment_link_transactions",
};

export const PAYMENT_LINK_TRANSACTIONS_ROUTE_IDS = {
  [PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS.PAYMENT_LINKS_TRANSACTIONS]:
    "payment-links-transactions",
};

export const PAYMENT_LINKS_TRANSACTIONS_COLUMN_KEYS = {
  payment_link_type: "payment_link_type",
  payment_link_url: "payment_link_url",
};

export const PAYMENT_LINKS_TRANSACTIONS_FILTER_KEYS = {
  region: "region",
};

export const PRICE_REGION_CHOICES = {
  DOMESTIC: 0,
  INTERNATIONAL: 1,
};

export const PAYMENT_LINKS_TRANSACTIONS_TABLE_CONFIG = ({
  hidePricePerSlot,
  hideTransactionAmount,
  hideNumberOfUnits,
  hidePaymountAmount,
  hidePayoutStatus,
  hidePayoutDate,
  isCustomerDetailsHidden,
  isOfferingAutoDebitEnabled,
}: {
  hidePricePerSlot: boolean;
  hideTransactionAmount: boolean;
  hideNumberOfUnits: boolean;
  hidePaymountAmount: boolean;
  hidePayoutStatus: boolean;
  hidePayoutDate: boolean;
  isCustomerDetailsHidden: boolean;
  isOfferingAutoDebitEnabled: boolean;
}) => [
  getTransactionCustomerNameConfig(),
  getTransactionCustomerPhoneConfig({ isCustomerDetailsHidden }),
  getTransactionCustomerEmailConfig({ isCustomerDetailsHidden }),
  getTransactionOfferingOwnerConfig(),
  getTransactionOfferingNameConfig(),
  getTransactionOfferingTypeConfig(),
  getTransactionPaymentTypeConfig(),
  ...(isOfferingAutoDebitEnabled ? [getTransactionDebitTypeConfig()] : []),
  getTransactionPricePerSlotConfig({ hidePricePerSlot }),
  getTransactionAmountConfig({ hideTransactionAmount }),
  getTransactionPaymentModeConfig(),
  getTransactionTransactionIdConfig(),
  getTransactionDateConfig(),
  getTransactionUnitsConfig({ hideNumberOfUnits }),
  getTransactionInternationalConfig(),
  getTransactionPaymentAmountConfig({ hidePaymountAmount }),
  getTransactionPayoutDateConfig({ hidePayoutDate }),
  getTransactionGSTInfoConfig(),
  getTransactionBillingStateConfig(),
  getTransactionCustomerInvoiceConfig({
    hideNumberOfUnits,
    isCustomerDetailsHidden,
  }),
  getTransactionPaymentLinkTypeConfig(),
  getTransactionPaymentLinkConfig(),
  getTransactionUTRInfoConfig(),
  getTransactionTypeConfig(),
  getTransactionPayoutStatusConfig({ hidePayoutStatus }),
];

export const PAYMENT_LINKS_TRANSACTIONS_TABLE_FILTERS_CONFIG = ({
  staffMembers,
  showStaffMembers,
  isOfferingAutoDebitEnabled,
}: {
  staffMembers: any[];
  showStaffMembers: boolean;
  isOfferingAutoDebitEnabled: boolean;
}) => [
  ...filterConfig(staffMembers, showStaffMembers, isOfferingAutoDebitEnabled),
  getTransactionsSourceFilterConfig(),
  getIsInternationalTransactionFilterConfig(),
];

export const PAYMENT_LINKS_TRANSACTIONS_TABLE_FILTER_CHIPS_FORMATTERS = {
  [PAYMENT_LINKS_TRANSACTIONS_COLUMN_KEYS.payment_link_type]:
    formPaymentlinkType,
  [PAYMENT_LINKS_TRANSACTIONS_FILTER_KEYS.region]:
    formatInternationalTransactionType,
};

export const tableChipsFormatter =
  (staffMembers: any[]) => (filter: string, value: string | number) => {
    const formatter = {
      ...ALL_TRANSACTIONS_TABLE_FILTER_CHIPS_FORMATTERS(staffMembers),
      ...PAYMENT_LINKS_TRANSACTIONS_TABLE_FILTER_CHIPS_FORMATTERS,
    }[filter];
    return formatter ? formatter(value) : undefined;
  };
