import { apiMethods } from "data/api.constants";
import { WHATS_NEXT_APIS } from "../WhatsNext.api";
import { isRequestSuccessful } from "utils/Utils";
import { setNextStepsInLocalStorage } from "utils/AuthUtil";
import { LOCAL_STORAGE_KEYS } from "features/Common/modules/Storage/modules/LocalStorage/constants/LocalStorage.constants";
import { getLocalStorageItem } from "features/Common/modules/Storage/modules/LocalStorage/utils/LocalStorage.utils";
import { dataProvider } from "data";
import { WHATS_NEXT_CUSTOM_EVENT } from "../WhatsNext.constants";
import { logError } from "utils/error";

/**
 * Fetches the latest "What's Next" progress information, updates the local storage with the returned data,
 * and dispatches a custom `whatsNextDataUpdate` event to notify other components of the update.
 *
 * @function
 * @param {Object} params - The parameters object.
 * @param {Array<string>} [params.keys=[]] - An array of keys that represent the specific setup steps to query for their progress.
 *
 * @returns {void}
 *
 * @description
 * This function sends a custom request to fetch progress data for the specified `keys` via the `GET_PROGRESS` API.
 * If the request is successful, it stores the response data in local storage using `setNextStepsInLocalStorage`
 * and dispatches a `whatsNextDataUpdate` event to allow any listeners to respond to the updated local storage.
 */

// Update function to use async/await
export const getWhatsNextUpdatedInfo = async ({ keys = [] }) => {
  try {
    const steps = await dataProvider.custom_request(
      WHATS_NEXT_APIS.GET_PROGRESS,
      apiMethods.GET,
      {
        request_params: JSON.stringify(keys),
      }
    );

    if (steps && isRequestSuccessful(steps.status)) {
      setNextStepsInLocalStorage({ data: steps.data });

      // event listener for this is present in features/Onboarding/modules/WhatsNext/utils/useWhatsNextLocalStorageChange.js
      const event = new Event(WHATS_NEXT_CUSTOM_EVENT);
      window.dispatchEvent(event);
    }
  } catch (error) {
    logError({
      occureedAt:
        "getWhatsNextUpdatedinfo in src/features/Onboarding/modules/WhatsNext/utils/whatsNext.utils.js",
      when: "calling progress api",
    });
  }
};

export function isAllStepsCompleted() {
  const nextSteps = getWhatsNextInfo();
  return nextSteps?.completion_percentage === 100;
}

export function getWhatsNextInfo() {
  const whatsNextInfo = getLocalStorageItem(LOCAL_STORAGE_KEYS.NEXT_STEPS);
  return whatsNextInfo ? JSON.parse(whatsNextInfo) : {};
}
