import { app_route_ids, app_route_keys } from "constants/urlPaths";
import React from "react";
import { Redirect } from "react-router-dom";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";
import ExlyLoader from "ui/modules/ExlyLoader";
import { useIsMediaLibEnabled } from "./utils/useIsMediaLibEnabled";

const MediaLibrary = React.lazy(() => import("ui/pages/MediaLibrary"));

export const MediaLibraryRoute = (props) => {
  const { isMediaLibraryEnabled, loading } = useIsMediaLibEnabled();
  const { location } = props;

  if (loading) return <ExlyLoader />;

  return isMediaLibraryEnabled ? (
    <LazyLoadRoutes LazyElement={MediaLibrary} props={props} />
  ) : (
    <Redirect
      to={{
        pathname: app_route_ids[app_route_keys.dashboard],
        state: { from: location },
      }}
    />
  );
};
