import {
  RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS,
  RECORDED_CONTENT_FILTER_VALUE_OPTIONS_LIST,
  RECORDED_CONTENT_FILTER_VALUE_OPTIONS_MAP,
  RECORDED_CONTENT_MEDIA_TYPES,
} from "features/Listings/constants/RecordedContent.constants";

export const MEDIA_LIBRARY_ROUTE_KEYS = {
  MEDIA_LIBRARY: "MEDIA_LIBRARY",
};

export const MEDIA_LIBRARY_ROUTE_IDS = {
  [MEDIA_LIBRARY_ROUTE_KEYS.MEDIA_LIBRARY]: "/media-library",
};

export const media_lib_types = {
  video: 1,
  image: 2,
  text: 3,
  attachment: 4,
  link: 5,
  pdf: 6,
  embed: 7,
  exam: 8,
  form: 9,
  liveSession: 10,
};

export const MEDIA_LIBRARY_FILTER_KEYS = {
  file_name: "search",
  file_type: "type",
};

const MEDIA_LIBRARY_FILTERS_LABELS = {
  [MEDIA_LIBRARY_FILTER_KEYS.file_name]: "Name",
  [MEDIA_LIBRARY_FILTER_KEYS.file_type]: "File Type",
};

export const MEDIA_LIBRARY_FILTER_CONFIG = [
  {
    source: MEDIA_LIBRARY_FILTER_KEYS.file_name,
    placeholder: "Name",
    type: "input",
    disableContains: true,
  },
  {
    source: MEDIA_LIBRARY_FILTER_KEYS.file_type,
    placeholder: "File Type",
    type: "dropdown",
    disableContains: true,
    options: RECORDED_CONTENT_FILTER_VALUE_OPTIONS_LIST,
  },
];

export const media_type_to_filter_key_map = {
  [RECORDED_CONTENT_MEDIA_TYPES.image]:
    RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.images,
  [RECORDED_CONTENT_MEDIA_TYPES.video]:
    RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.videos,
  [RECORDED_CONTENT_MEDIA_TYPES.attachment]:
    RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.other_files,
  [RECORDED_CONTENT_MEDIA_TYPES.pdf]:
    RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.other_files,
};

export const MEDIA_LIBRARY_FILTERS_FORMATTER = (filter, value) => {
  switch (filter) {
    case MEDIA_LIBRARY_FILTER_KEYS.file_name:
      return `${
        MEDIA_LIBRARY_FILTERS_LABELS[MEDIA_LIBRARY_FILTER_KEYS.file_name]
      }: ${value}`;
    case MEDIA_LIBRARY_FILTER_KEYS.file_type:
      return `${
        MEDIA_LIBRARY_FILTERS_LABELS[MEDIA_LIBRARY_FILTER_KEYS.file_type]
      }: ${RECORDED_CONTENT_FILTER_VALUE_OPTIONS_MAP[value].label}`;
    default:
      return undefined;
  }
};

export const MEDIA_LIBRARY_APIS = {
  add_media: "host/create/content",
  update_media: "host/content/update",
  bulk_delete: "host/content/delete/bulk",
  media_type_existency: "host/content/media/type/existency",
  vdocipher: {
    upload_creds: "host/get/vdociper/upload/credentials",
    download_url: "host/vdocipher/video/dowload/url/fetch",
  },
};

export const UPLOAD_QUEUE_STATUS = {
  PENDING: 1,
  UPLOADING: 2,
  SUCCESS: 3,
  FAILED: 4,
};

export const REDUX_STATE_KEYS = {
  type: "type",
  search: "search",
  upload_queue: "upload_queue",
  upload_counter: "upload_counter",
  loading: "loading",
  loading_more: "loading_more",
  total: "total",
  total_pages: "totalPages",
  concurrent_uploads_count: "concurrent_uploads_count",
  show_video_uploader_modal: "show_video_uploader_modal",
  refetching_on_upload_success: "refetching_on_upload_success",
  upload_worker_started: "upload_worker_started",
  media_listing: "mediaListing",
  page: "page",
};

export const MAX_WISTIA_CONCURRENT_FILE_UPLOADS = 4;
export const MAX_VDOCIPHER_CONCURRENT_FILE_UPLOADS = 2;
export const MEDIA_LIBRARY_MAX_VIDEO_FILES_LIMIT_TO_UPLOAD = 20;

export const VDOCIPHER_VIDEO_STATUS = {
  READY: "ready",
};
