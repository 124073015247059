import React from "react";
import { PAYOUT_STATUS } from "ui/pages/transactions/AllTransaction/components/TableFields/TableFields";
import {
  LINK_TYPE_LABELS,
  LINK_TYPES,
} from "../../CreatePaymentLinkModal/constants/CreatePaymentLinkModal.constants";
import LinkField from "../../ExistingPaymentLinks/components/LinkField/LinkField";
import {
  EXISTING_PAYMENT_LINKS_COLUMN_KEYS,
  EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS,
} from "../../ExistingPaymentLinks/constants/ExistingPaymentLinks.constants";
import {
  PAYMENT_LINKS_TRANSACTIONS_COLUMN_KEYS,
  PAYMENT_LINKS_TRANSACTIONS_FILTER_KEYS,
  PRICE_REGION_CHOICES,
} from "../constants/PaymentLinksTransactions.constants";
import { IPaymentLinksTransactions } from "../interfaces/IPaymentLinksTransactions";
import styles from "../PaymentLinksTransactions.module.css";

export const getTransactionPaymentLinkTypeConfig = () => ({
  field: PAYMENT_LINKS_TRANSACTIONS_COLUMN_KEYS.payment_link_type,
  headerName: "Source",
  emptyField: "N/A",
  width: "150px",
  sortable: false,
  valueFormatter:
    EXISTING_PAYMENT_LINKS_VALUE_FORMATTERS[
      EXISTING_PAYMENT_LINKS_COLUMN_KEYS.payment_link_type
    ],
});

export const getTransactionPaymentLinkConfig = () => {
  const formatPaymentLink = (
    record: IPaymentLinksTransactions.ITransaction
  ) => <LinkField link={record.payment_link_url} />;

  return {
    field: PAYMENT_LINKS_TRANSACTIONS_COLUMN_KEYS.payment_link_url,
    headerName: "Link",
    emptyField: "N/A",
    sortable: false,
    valueFormatter: formatPaymentLink,
  };
};

export const getTransactionsSourceFilterConfig = () => ({
  source: PAYMENT_LINKS_TRANSACTIONS_COLUMN_KEYS.payment_link_type,
  placeholder: "Source",
  options: [
    {
      label: LINK_TYPE_LABELS[LINK_TYPES.UNIVERSAL_LINK],
      value: LINK_TYPES.UNIVERSAL_LINK,
    },
    {
      label: LINK_TYPE_LABELS[LINK_TYPES.CUSTOM_LINK],
      value: LINK_TYPES.CUSTOM_LINK,
    },
    {
      label: LINK_TYPE_LABELS[LINK_TYPES.OPEN_LINK],
      value: LINK_TYPES.OPEN_LINK,
    },
  ],
  type: "dropdown",
  disableContains: true,
});

export const getIsInternationalTransactionFilterConfig = () => ({
  source: PAYMENT_LINKS_TRANSACTIONS_FILTER_KEYS.region,
  placeholder: "International transaction",
  options: [
    { label: "Yes", value: PRICE_REGION_CHOICES.INTERNATIONAL },
    { label: "No", value: PRICE_REGION_CHOICES.DOMESTIC },
  ],
  type: "dropdown",
  disableContains: true,
});

export const formPaymentlinkType = (value: number | string) =>
  `Source: ${LINK_TYPE_LABELS[value as number]}`;

export const formatInternationalTransactionType = (
  value: number | string | boolean
) =>
  `International transaction: ${
    value === PRICE_REGION_CHOICES.INTERNATIONAL ? "Yes" : "No"
  }`;

export const getRecordFooterClassName = (
  record: IPaymentLinksTransactions.ITransaction
) => {
  // @ts-ignore
  const status_variant = PAYOUT_STATUS[record.payout_status]?.name;
  if (!status_variant) return "";
  return styles[`all_transaction_mobile_footer_${status_variant}`];
};

export const handleSeeMore = (
  row: IPaymentLinksTransactions.ITransaction,
  rows: IPaymentLinksTransactions.ITransaction[],
  // eslint-disable-next-line no-unused-vars
  setDrawerConfig: (args: {
    drawerActionType: string;
    open: Boolean;
    row: IPaymentLinksTransactions.ITransaction;
    rowIndex: number;
  }) => void,
  setActionsDrawerConfig: () => void,
  rowIndex: number
) =>
  setDrawerConfig({
    drawerActionType: "view",
    open: true,
    row,
    rowIndex,
  });
