import { ONBOARDING_FLOW_KEY } from "features/Onboarding/constants/Onboarding.urlPaths";
import { appendUrlParams, getRAUrlParams } from "utils/urlUtils";
import {
  FIRST_ONBOARDING_QUESTION_KEY,
  ONBOARDING_QUESTIONS,
  location_key,
} from "../constants/OnboardingQuestions.constants";

/**
 * Redirects to the onboarding questions flow with appropriate URL parameters.
 *
 * @param {Object} options - The options for redirection.
 * @param {string} options.key - The key representing the specific onboarding question flow.
 * @param {Object} [options.params={}] - Additional parameters for redirection.
 * @param {string} [options.params.location] - The location parameter for redirection.
 */
export const handleOnboardingQuestionsRedirection = ({
  key = FIRST_ONBOARDING_QUESTION_KEY,
  params = {},
} = {}) => {
  const urlParams = getRAUrlParams();
  const locationParam = {
    location: params?.location || urlParams.get(location_key),
  };

  const routeKey = ONBOARDING_QUESTIONS[key]?.route_key;
  window.location.href = `/#/${appendUrlParams(
    `${ONBOARDING_FLOW_KEY}/${routeKey}`,
    locationParam
  )}`;
};
