import { FIRST_ONBOARDING_QUESTION_KEY } from "../modules/OnboardingQuestions/constants/OnboardingQuestions.constants";
import {
  FETCH_QUESTIONS_DATA,
  SET_QUESTIONS_LOADING,
  SET_QUESTIONS_DATA,
  SET_SELECTED_INFO_VALUE,
  SET_SELECTED_QUESTION_KEY,
  ONBOARDING__TOGGLE_WELCOME_MODAL,
  ONBOARDING__TOGGLE_BONUS_MODAL,
} from "./Onboarding.actions";

const initialState = {
  // used to maintain the value of "option", whose additional information is to be shown on right side of the screen
  // currently used in ONBOARDING_QUESTIONS.SALES_MARKETING_SUITE && ONBOARDING_QUESTIONS.OFFERINGS_SELECTION
  // option refers to values from offerings_selection_values && sales_marketing_suites_values
  selectedInfoValue: null,
  loading: false,
  questionLoading: false,
  currentQuestionKey: FIRST_ONBOARDING_QUESTION_KEY,
  questionResponses: {},
  showWelcomeModal: false,
  openBonusMpodal: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_INFO_VALUE: {
      return {
        ...state,
        selectedInfoValue: payload,
      };
    }

    case ONBOARDING__TOGGLE_WELCOME_MODAL: {
      return {
        ...state,
        showWelcomeModal: payload,
      };
    }
    case ONBOARDING__TOGGLE_BONUS_MODAL: {
      return {
        ...state,
        openBonusModal: payload,
      };
    }
    case FETCH_QUESTIONS_DATA:
      return { ...state, loading: true };
    case SET_QUESTIONS_LOADING:
      return { ...state, questionLoading: payload };
    case SET_QUESTIONS_DATA:
      return {
        ...state,
        loading: false,
        questionResponses: { ...state.questionResponses, ...payload },
      };
    case SET_SELECTED_QUESTION_KEY:
      return { ...state, currentQuestionKey: payload };

    default: {
      return state;
    }
  }
};

export default reducer;
