import React from "react";
import { UPFRONT_PLAN_TYPES } from "features/Account/Account.constants";

export const THANKYOU_PAGE_VISIT_STATES = {
  NOT_NEEDED: 1,
  PENDING: 2,
  COMPLETED: 3,
};

// constant config to be used on onboarding thankyou screen based on Upfront plan type
export const ONBOARDING_THANKYOU_CONFIG = {
  [UPFRONT_PLAN_TYPES.PRO]: {
    default_background: "#AEA2FF",
    background_image: `${process.env.REACT_APP_EXLY_CONTENT_IMAGES_CDN_URL}/onboarding/pro_bg.svg?format=auto&width=720`,
    carousel_background_image: `${process.env.REACT_APP_EXLY_CONTENT_IMAGES_CDN_URL}/onboarding/carousel_bg.svg?format=auto&width=720`,
    chip: `${process.env.REACT_APP_EXLY_CONTENT_IMAGES_CDN_URL}/onboarding/pro_chip.svg?format=auto&width=720`,
    chip_classname: "proChip",
    welcome_text: "Welcome to the club",
    welcome_text_classname: "proWelcomeText",
    feature_heading: "With your plan, you get access to...",
    feature_heading_classname: "proFeatureHeading",
    description_text: (
      <>
        <b>Thank you for choosing the Pro Plan! </b>
        <br />
        Your commitment to advancing your business with our powerful features
        means the world to us. We&apos;re here to support you every step of the
        way and are excited to see your journey unfold.&nbsp;
      </>
    ),
    description_text_classname: "proDesc",
    tag_line: "Let's achieve great things together!",
    skip_config: { text_color: "#fff", border_color: "#DBD6FF" },
  },
  [UPFRONT_PLAN_TYPES.PREMIUM]: {
    default_background: "#493AB1",
    background_image: `${process.env.REACT_APP_EXLY_CONTENT_IMAGES_CDN_URL}/onboarding/premium_stars.svg?format=auto&width=720`,
    carousel_background_image: `${process.env.REACT_APP_EXLY_CONTENT_IMAGES_CDN_URL}/onboarding/carousel_premium_bg.svg?format=auto&width=720`,
    chip: `${process.env.REACT_APP_EXLY_CONTENT_IMAGES_CDN_URL}/onboarding/premium_chip.svg?format=auto&width=720`,
    chip_classname: "premiumChip",
    welcome_text: "Welcome to the club",
    welcome_text_classname: "premiumWelcomeText",
    feature_heading: "With your plan, you get access to...",
    feature_heading_classname: "premiumFeatureHeading",
    description_text: (
      <>
        <b>Thank you for opting for the Premium Plan! </b>
        <br />
        Your trust in us to provide the best tools for your business is truly
        appreciated. We&apos;re thrilled to have you with us and can&apos;t wait
        to help you soar to new heights. Together, let&apos;s create something
        extraordinary!
      </>
    ),
    description_text_classname: "premiumDesc",
    tag_line: "Together let’s create something extraordinary!",
    skip_config: { text_color: "#EAE7FF", border_color: "#AEA2FF" },
  },
};

export const DEFAULT_AUTO_PLAY_TIMING = 1500; //0.5 secs
export const DEFAULT_AUTO_REDIRECTION_TIMING = 180000; // 3 MINS
export const SKIP_BTN_DISPLAY_DELAY_TIME = 10000; // 10 sec

export const thankyou_token_key = "q"; // query param key in thankyou page url for token
