import React from "react";
import { Route } from "react-router-dom";
import { MediaLibraryRoute } from "../MediaLibraryRoute";
import {
  MEDIA_LIBRARY_ROUTE_IDS,
  MEDIA_LIBRARY_ROUTE_KEYS,
} from "./MediaLibrary.constants";

export const MEDIA_LIBRARY_CUSTOM_ROUTES = [
  <Route
    key={MEDIA_LIBRARY_ROUTE_IDS[MEDIA_LIBRARY_ROUTE_KEYS.MEDIA_LIBRARY]}
    path={MEDIA_LIBRARY_ROUTE_IDS[MEDIA_LIBRARY_ROUTE_KEYS.MEDIA_LIBRARY]}
    component={MediaLibraryRoute}
  />,
];
