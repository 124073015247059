import { COMMON_WIDTH_RESOLUTIONS } from "features/Common/modules/ExlyImage/constants/ExlyImage.constants";
import { getOptimisedS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import {
  WHATS_NEXT_ASSETS_URL,
  whats_next_keys,
} from "features/Onboarding/modules/WhatsNext/WhatsNext.constants";

export const GET_LEADS_SCROLLABLE_DEV = "leads-scrollable-div";

export const GET_LEADS_KEYS = {
  HAS_RUN_ADS: whats_next_keys.has_run_ads.key,
  HAS_SALES_PAGE: whats_next_keys.has_sales_page.key,
  HAS_SHARED_SALES_PAGE: whats_next_keys.has_shared_sales_page.key,
};

export const SALES_PAGES_VIDEO_LINK =
  "https://www.youtube-nocookie.com/watch?v=aEQnNaoBQfQ";
export const RUN_FB_ADS_VIDEO_LINK =
  "https://www.youtube-nocookie.com/watch?v=Dw4OoPN2gz0";
export const IMPORT_LEADS_VIDEO_LINK =
  "https://www.youtube-nocookie.com/watch?v=Eyw3-WCLTqc";

export const boxStyle = {
  "--bg-image": `url(${getOptimisedS3AssetCDNUrl(
    `${WHATS_NEXT_ASSETS_URL}/leads_bg.svg`
  )}?format=auto&width=${COMMON_WIDTH_RESOLUTIONS.WIDTH_720})`,
};

export const get_lead_icons = {
  create_sale_page: getOptimisedS3AssetCDNUrl(
    `${WHATS_NEXT_ASSETS_URL}/leads4.svg`
  ),
  share_sale_page: getOptimisedS3AssetCDNUrl(
    `${WHATS_NEXT_ASSETS_URL}/leads1.svg`
  ),
  run_ads: getOptimisedS3AssetCDNUrl(`${WHATS_NEXT_ASSETS_URL}/leads2.svg`),
  import_leads: getOptimisedS3AssetCDNUrl(
    `${WHATS_NEXT_ASSETS_URL}/leads3.svg`
  ),
};
